import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import AddForm from "../customer/addForm";
import ViewForm from "../customer/viewForm";
import CompanyAddAndUpdateForm from "src/components/Modal/editAndAddForm";
import ReactPaginate from "react-js-pagination";
import CompanyAllDetail from "../company/companyAllDetails";
import { getAllConsumers } from "src/api/customerApi";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { CSVLink, CSVDownload } from "react-csv";

const Customers = () => {
  const [show, setShow] = useState(false);
  const [ShowAdd, setShowAdd] = useState(false);
  const [operation, setOpearation] = useState("");
  const [showAccordion, setShowAccordion] = useState(false);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [model, setModel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editLoanData, setEditLoanData] = useState(null);
  const [updateForm, setUpdate] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [customerData, setCustomerData] = useState([]);

  const SchemaName = sessionStorage.getItem("schemaName");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getAllCustomer();
  }, []);

  const handleSearchChange = (e) => {
    // Step 2: Update the searchInput state as the user types
    setSearchInput(e.target.value);
  };

  const handleEditLoan = (company) => {
    setShowAdd(true);
    setOpearation("update");
    setEditLoanData(company);
  };

  const handleViewDetails = (company) => {
    navigate("/preview-data", { state: { data: company } });
  };
  const handleshowAddForm = () => {
    setEditLoanData({});
    setShowAdd(true);
    // setOpearation("add");
    setOpearation("view");
  };

  const getAllCustomer = () => {
    const payload = { schemaName: SchemaName };
    getAllConsumers(payload)
      .then((res) => setCustomerData(res?.data))
      .catch((err) => console.log("err", err));
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = customerData?.slice(indexOfFirstRow, indexOfLastRow);
  const modifiedRows = currentRows.map(row => {
    const { packageDetails, ...rest } = row;
    return {
      ...rest,
      packageName: packageDetails.packageName,
      packageAmount: packageDetails.packageAmount,
      packageStartingData: packageDetails.packageStartingData,
      packageEndData: packageDetails.packageEndData,
    };
  });

  // const handleDownload = () => {};

  return (
    <div>
      {/* <h4>Customer Component</h4> */}
      {show && <ViewForm show={show} setShow={setShow} />}
      {ShowAdd ? (
        <AddForm loanData={editLoanData} ShowAdd={ShowAdd} setShowAdd={setShowAdd} isUpdate={_.isEmpty(editLoanData)} />
      ) : (
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Customer</strong>
              </CCardHeader>
              <div className="search_bar">
                {showSelectAndSearch && (
                  <div>
                    <CFormSelect
                      size="sm"
                      className="mb-3"
                      style={{ cursor: "pointer" }}
                      aria-label="Small select example"
                      onChange={(e) => setRowsPerPage(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </CFormSelect>
                  </div>
                )}
                <div style={{ display: "flex" }}>
                  {showSelectAndSearch && (
                    <>
                      <CFormInput
                        type="text"
                        size="sm"
                        placeholder="Search"
                        aria-label="sm input example"
                        style={{ margin: "0px 0px 16px 0px" }}
                        onChange={handleSearchChange}
                        value={searchInput}
                      />

                      <span
                        style={{
                          border: " 1px solid #d1c5c5",
                          borderRadius: "4px",
                          margin: "0px 5px 16px -1px",
                          padding: "1px 8px 0px 8px",
                          background: "#ebedef",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </span>
                    </>
                  )}
                </div>
                {showSelectAndSearch && (
                  <div className="btn_add mt-0 mb-14">
                    <CButton className="add-btn w-auto plr-30 mr-10" onClick={handleshowAddForm}>
                      Add
                    </CButton>
                    <CSVLink data={modifiedRows} filename={'Customer_Data.csv'}>
                      <CButton className="add-btn w-auto plr-30 mr-10" style={{ marginLeft: 20, marginRight: 90 }}>
                        Download
                      </CButton>
                    </CSVLink>
                    ;
                  </div>
                )}
              </div>

              <CCardBody>
                <div className={`table-container ${showAccordion ? "hidden" : ""}`}>
                  <CTable color="dark" hover className="">
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col" className="col-md-1">
                          S. No.
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="col-md-2">
                          Customer Name
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="col-md-2">
                          Alias Name
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="col-md-2">
                          Address
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="col-md-2">
                          Mobile No
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="col-md-2">
                          Email
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="col-md-2">
                          Action
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {currentRows && currentRows?.length > 0 ? (
                        currentRows
                          ?.filter((company) => company?.consumerName?.toLowerCase().includes(searchInput.toLowerCase()))
                          .map((item, id) => {
                            return (
                              <CTableRow key={id}>
                                <CTableDataCell>{id + 1}</CTableDataCell>
                                <CTableDataCell>{item?.consumerName || "--"}</CTableDataCell>
                                <CTableDataCell>{item?.aliasName || "--"}</CTableDataCell>
                                <CTableDataCell>{item?.address || "--"}</CTableDataCell>
                                <CTableDataCell>{item?.mobileNumber || "--"}</CTableDataCell>
                                <CTableDataCell>{item?.email || "--"}</CTableDataCell>
                                <CTableDataCell className="col-md-6">
                                  <>
                                    <Tooltip text="View">
                                      <FontAwesomeIcon
                                        role="button"
                                        icon={faEye}
                                        size="1x"
                                        className="viewIcon"
                                        onClick={() => handleViewDetails(item)}
                                      />
                                    </Tooltip>

                                    <Tooltip text="Update">
                                      <FontAwesomeIcon
                                        role="button"
                                        icon={faPenToSquare}
                                        size="1x"
                                        onClick={() => handleEditLoan(item)}
                                        className="px-3 updateIcon"
                                      />
                                    </Tooltip>
                                  </>
                                </CTableDataCell>
                              </CTableRow>
                            );
                          })
                      ) : (
                        <CTableDataCell
                          colSpan={6}
                          style={{
                            textAlign: "center",
                            color: "red !important",
                          }}
                        >
                          No Record Found!
                        </CTableDataCell>
                      )}
                    </CTableBody>
                  </CTable>
                  {model === "update" ? (
                    <CompanyAddAndUpdateForm setVisible={setModel} visible={model} loanData={editLoanData} updateForm={updateForm} />
                  ) : (
                    ""
                  )}

                  {model === "add" ? (
                    <CompanyAddAndUpdateForm
                      // visible={visibleUser}
                      // setVisible={setVisibleUser}
                      setVisible={setModel}
                      visible={model}
                    />
                  ) : (
                    ""
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "16px", fontWeight: "600" }}>
                      Showing {indexOfFirstRow + 1} to {Math?.min(indexOfLastRow, customerData?.length)} of {customerData?.length} entries
                    </div>

                    <div className="pagination-container">
                      <ReactPaginate
                        activePage={currentPage}
                        itemsCountPerPage={rowsPerPage}
                        totalItemsCount={customerData?.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        prevPageText="Previous"
                        nextPageText="Next"
                        firstPageText="First"
                        lastPageText="Last"
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>

                {showAccordion && (
                  <CompanyAllDetail
                    selectedCompany={selectedCompany}
                    setShowAccordion={setShowAccordion}
                    setShowSelectAndSearch={setShowSelectAndSearch}
                  />
                )}
              </CCardBody>
            </CCard>
          </CCol>

          <CCol xs={12}>
            <div className={`accordion-container ${showAccordion ? "visible" : ""}`}></div>
          </CCol>
        </CRow>
      )}
    </div>
  );
};

export default Customers;