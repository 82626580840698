import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { AppSidebarNav } from "./AppSidebarNav";
// import Vakilkaro from "../assets/brand/vakilkaro";
import { sygnet } from "src/assets/brand/sygnet";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "../navigations/Menu";
import { setSidebarVisibility } from "src/redux/actions/planAction";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const { sidebarShow } = useSelector((state) => state?.planReducer);
  const { selectedRoleType } = useSelector((state) => state?.staffReducer);

  const [activeMenuItem, setActiveMenuItem] = useState("");

  const handleMenuItemClick = (name) => {

    setActiveMenuItem(name);
  };

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(setSidebarVisibility(visible));
      }}
    >
      <CSidebarBrand className="d-none d-md-flex bg-white" to="/">
        {/* <Vakilkaro /> */}
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          {/* Pass the activeMenuItem and handleMenuItemClick as props */}
          <AppSidebarNav
            items={navigation}
            activeMenuItem={activeMenuItem}
            onMenuItemClick={handleMenuItemClick}
            selectedRoleType={selectedRoleType}
          />
        </SimpleBar>
      </CSidebarNav>
      {/* ... */}
    </CSidebar>
  );
};

export default React.memo(AppSidebar);