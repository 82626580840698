import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  ERROR,
  GET_ALL_STAFFS,
  ADD_STAFFS_DATA,
  EDIT_STAFFS_DATA,
  DELETE_STAFFS_BY_ID,
  UPDATE_STAFFS_BY_ID,
  GET_ALL_STAFFS_SUCCESS,
  ADD_STAFFS_DATA_SUCCESS,
  DELETE_STAFFS_BY_ID_SUCCESS,
  UPDATE_STAFFS_BY_ID_SUCCESS,
  // LOGIN,
  // LOGIN_SUCCESS,
  STAFF_LOGIN,
  STAFF_LOGIN_SUCCESS,
  ADMINISTRAOR_LOGIN,
  ADMINISTRAOR_LOGIN_SUCCESS,
  ADMIN_LOGIN,
  ADMIN_LOGIN_SUCCESS,
} from "../action_Types";

function* allStaff() {
  try {
    const response = yield call(authInstance.get, "");

    yield put({
      type: GET_ALL_STAFFS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addStaff(action) {

  try {
    const response = yield call(authInstance.post, "employee", action.payload);



    yield put({
      type: ADD_STAFFS_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {

    const err = error.response.data.message;

    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* editStaff(action) {
  try {
    const id = action?.payload?.get("id")
    const response = yield call(authInstance.patch, `employee/${id}`, action.payload);

    yield put({
      type: ADD_STAFFS_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {

    const err = error.response.data.message;

    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* stafflogin(action) {
  try {
    const response = yield call(
      authInstance.post,
      "/staff-login",
      action.payload
    );

    yield put({
      type: STAFF_LOGIN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}
function* adminstratorLogin(action) {
  try {
    const response = yield call(authInstance.post, "/login", action.payload);

    yield put({
      type: ADMINISTRAOR_LOGIN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {

    const err = error.response;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}
function* adminLogin(action) {
  try {
    const response = yield call(authInstance.post, "/login", action.payload);

    yield put({
      type: ADMIN_LOGIN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* companyLogin(action) {
  try {
    const response = yield call(
      authInstance.post,
      "company-login",
      action.payload
    );

    yield put({
      type: ADMIN_LOGIN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteStaff(action) {
  try {
    const response = yield call(authInstance.delete, `/${action.payload}`);

    yield put({
      type: DELETE_STAFFS_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateStaff(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `/${id}`, data);
    yield put({
      type: UPDATE_STAFFS_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* staffActionWatcher() {
  yield takeLatest(GET_ALL_STAFFS, allStaff);
  yield takeLatest(ADD_STAFFS_DATA, addStaff);
  yield takeLatest(EDIT_STAFFS_DATA, editStaff);
  yield takeLatest(DELETE_STAFFS_BY_ID, deleteStaff);
  yield takeLatest(UPDATE_STAFFS_BY_ID, updateStaff);
  yield takeLatest(STAFF_LOGIN, stafflogin);
  yield takeLatest(ADMINISTRAOR_LOGIN, adminstratorLogin);
  yield takeLatest(ADMIN_LOGIN, companyLogin);
}
