import React, { useEffect, useRef, useState } from "react";
import { CModal, CButton, CModalBody, CCol, CContainer, CForm, CModalHeader, CModalTitle, CRow, CFormInput, CFormSelect, CFormLabel } from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { addCompany, updateCompany } from "src/api/hospitalApi";
import { refreshPage } from "src/redux/actions/customerAction";
import { getAllScehems } from "src/api/allApis";

const initialValues = {
  userName: "",
  password: "",
  hospitalName: "",
  hospitalEmail: "",
  hospitalMobileNumber: "",
  gstNumber: "",
  shortName: '',
  startDate: '',
  dueDate: '',
  activeStatus: '',
  packagess: ''
};

const initialAddress = {
  ploatNumber: '',
  city: '',
  state: '',
  country: '',
  zipCode: ''
}

const CompanyAddAndUpdateForm = ({ visible, setVisible, loanData, updateForm }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();


  useEffect(() => {
    if (loanData?.hospitalAddress) {
      try {
        const parsedData = JSON.parse(loanData.hospitalAddress);
        setAddress(parsedData);
      } catch (error) {
        console.error('Error parsing hospitalAddress:', error);
        // Handle parsing error if necessary
      }
    }
  }, [loanData]);

  const [companyData, setCompanyData] = useState(loanData || initialValues)
  const [address, setAddress] = useState(initialAddress)
  const [imageFile, setImageFile] = useState(null)

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
    setImageFile(fileInputRef?.current?.files[0])
  };

  const handleChangeAddressValue = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const [packages, setPackages] = useState([]);

  const fetchPackages = async () => {
    const res = await getAllScehems({ role: "super admin" });
    setPackages(res?.data || []);
  }

  useEffect(() => {
    fetchPackages();
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { userName, password, hospitalName, hospitalEmail, hospitalMobileNumber,
        gstNumber, shortName, startDate, dueDate, activeStatus, packagess
      } = companyData

      const formData = new FormData();

      formData.append("userName", userName);
      formData.append("password", password);
      formData.append("hospitalName", hospitalName);
      formData.append("hospitalEmail", hospitalEmail);
      formData.append("hospitalMobileNumber", hospitalMobileNumber);
      formData.append("hospitalAddress", JSON.stringify(address));
      formData.append("hospitalLogo", imageFile);
      formData.append("gstNumber", gstNumber);
      formData.append("shortName", shortName);
      formData.append("startDate", startDate);
      formData.append("dueDate", dueDate);
      formData.append("activeStatus", activeStatus);
      formData.append("package_id", parseInt(packagess));
      if (!updateForm) {
        addCompany(formData)
          .then((res) => {
            if (res.status === "success") {
              setVisible(false)
              dispatch(refreshPage())
            }
          })
          .catch(err => console.log("err 55", err))
      } else {
        formData.append("schemaName", loanData.schemaName)
        updateCompany(formData, loanData?.id)
          .then((res) => {
            if (res.status === "success") {
              setVisible(false)
              dispatch(refreshPage())
            }
          })
          .catch(err => console.log("err 55", err))
      }
    } catch (error) {
      console.log("error 57", error)
    }
  };

  const {
    userName,
    password,
    hospitalName,
    hospitalEmail,
    hospitalMobileNumber,
    gstNumber,
    shortName,
    startDate,
    dueDate,
    activeStatus,
    packagess
  } = companyData

  const {
    ploatNumber,
    city,
    state,
    country,
    zipCode
  } = address

  return (
    <CModal
      backdrop="static"
      visible={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="StaticBackdropExampleLabel"
      size="lg"
    >
      <CModalHeader>
        <CModalTitle id="StaticBackdropExampleLabel">{updateForm ? "Edit Company" : "Add Company"}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer>
          {" "}
          <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
            <div className="company_header">Basic Details :</div>
            {updateForm ? '' :
              <CRow>
                <CCol xs={6}>
                  <div className="mb-4">
                    <label htmlFor="userName" className="mb-1">
                      Company Username
                    </label>
                    <CFormInput
                      id="userName"
                      type="text"
                      name="userName"
                      value={userName}
                      onChange={handleChangeValue}
                      required
                    />
                  </div>
                </CCol>
                <CCol xs={6}>
                  <div className="mb-4">
                    <label htmlFor="password" className="mb-1">
                      Company Password
                    </label>
                    <CFormInput
                      id="password"
                      type="password"
                      name="password"
                      value={password}
                      onChange={handleChangeValue}
                      required
                    />
                  </div>
                </CCol>
              </CRow>
            }

            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="hospitalName" className="mb-1">
                    Company Name
                  </label>
                  <CFormInput
                    id="hospitalName"
                    type="text"
                    name="hospitalName"
                    value={hospitalName}
                    onChange={handleChangeValue}
                    required
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="hospitalEmail" className="mb-1">
                    Company Mail Id
                  </label>
                  <CFormInput
                    id="hospitalEmail"
                    type="email"
                    name="hospitalEmail"
                    value={hospitalEmail}
                    onChange={handleChangeValue}
                    required
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="phone_number" className="mb-1">
                    Company Phone Number
                  </label>
                  <CFormInput
                    type="number"
                    id="phone_number"
                    name="hospitalMobileNumber"
                    value={hospitalMobileNumber}
                    onChange={handleChangeValue}
                    required
                  />
                </div>
              </CCol>
            </CRow>

            <div className="company_header">Address Details :</div>
            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="ploatNumber" className="mb-1">
                    Plot Number
                  </label>
                  <CFormInput
                    id="ploatNumber"
                    type="text"
                    name="ploatNumber"
                    value={ploatNumber}
                    onChange={handleChangeAddressValue}
                    required
                  />
                </div>
              </CCol>

              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="city" className="mb-1">
                    City
                  </label>
                  <CFormInput
                    id='city'
                    type="text"
                    name="city"
                    value={city}
                    onChange={handleChangeAddressValue}
                    required
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="state" className="mb-1">
                    State
                  </label>
                  <CFormInput
                    id="state"
                    type="text"
                    name="state"
                    value={state}
                    onChange={handleChangeAddressValue}
                    required
                  />
                </div>
              </CCol>

              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="contury" className="mb-1">
                    Conutry
                  </label>
                  <CFormInput
                    id="contury"
                    type="text"
                    name="country"
                    value={country}
                    onChange={handleChangeAddressValue}
                    required
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs={6}>
                <div className="">
                  <label htmlFor="zipCode" className="mb-1">
                    Zip Code
                  </label>
                  <CFormInput
                    id='zipCode'
                    type="number"
                    name="zipCode"
                    value={zipCode}
                    onChange={handleChangeAddressValue}
                    required
                  />
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-4">
              <div className="company_header">Other Details :</div>
              <CCol xs={6} >
                <CFormLabel htmlFor="hospitalLogo"> Company Logo{" "}</CFormLabel>
                <CFormInput
                  type="file"
                  ref={fileInputRef}
                  id="hospitalLogo"
                  placeholder=" Company Logo"
                  onChange={handleChangeValue}
                />
              </CCol>

              <CCol xs={6}>
                <div className="">
                  <CFormLabel htmlFor="gst_number" className="mb-1">
                    GST Number
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    id="gst_number"
                    name="gstNumber"
                    value={gstNumber}
                    onChange={handleChangeValue}
                    required
                  />
                </div>
              </CCol>
            </CRow>
            <div className="company_header">Package Details :</div>
            <CRow className="mb-4">
              <CCol xs={6}>
                <CFormLabel htmlFor="shortName">Package</CFormLabel>
                <CFormSelect
                  className="custom-select"
                  id="packagess"
                  name="packagess"
                  type="select"
                  value={packagess}
                  onChange={handleChangeValue}
                  required
                >
                  <option value="">Packages</option>
                  {packages?.map(item => (
                    <option key={item?.id} value={item.id}>{item?.schemename}</option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>

            <div className="company_header">Company Create Details :</div>
            <CRow className="mb-4">
              <CCol xs={6}>
                <CFormLabel htmlFor="shortName">Short Name</CFormLabel>
                <CFormInput
                  type="text"
                  id="shortName"
                  name="shortName"
                  value={shortName}
                  onChange={handleChangeValue}
                  required
                />
              </CCol>

              <CCol xs={6}>
                <div className="">
                  <CFormLabel htmlFor="startDate" className="mb-1">
                    Start Date
                  </CFormLabel>
                  <CFormInput
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    onChange={handleChangeValue}
                    required
                  />
                </div>
              </CCol>
            </CRow>

            <CRow className="mb-4">
              <CCol xs={6}>
                <CFormLabel htmlFor="dueDate">End Date</CFormLabel>
                <CFormInput
                  type="date"
                  id="dueDate"
                  name="dueDate"
                  value={dueDate}
                  onChange={handleChangeValue}
                  required
                />
              </CCol>

              <CCol xs={6}>
                <CFormLabel htmlFor="activeStatus">Company Status</CFormLabel>
                <CFormSelect
                  className="custom-select"
                  id="activeStatus"
                  name="activeStatus"
                  type="select"
                  value={activeStatus}
                  onChange={handleChangeValue}
                  required
                >
                  <option value="">Company Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </CFormSelect>
              </CCol>
            </CRow>

            <div style={{ display: 'flex', justifyContent: 'center' }} >
              <CButton type="submit" size="sm" color="primary">
                {/* <CButton color="primary" onClick={handleSubmit}> */}
                Submit
                {/* </CButton> */}
              </CButton>
            </div>
          </CForm>
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

CompanyAddAndUpdateForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  loanData: PropTypes.object,
  updateForm: PropTypes.bool,
};

export default CompanyAddAndUpdateForm;
