import { CButton, CCardBody, CCol, CContainer, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react";
import React, { useState } from "react";
import PropTypes from "prop-types";

const BasicDetails = (props) => {
  const _data = props.prefill || {};
  const _prefill = {
    customerName: _data.consumerName,
    aliasName: _data.aliasName,
    address: _data.address,
    phoneNo: _data.mobileNumber,
    emailId: _data.email,
  };
  const [mainData, setMainData] = useState(_prefill || props.formData);

  const { isUpdate = false } = props || {};
  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setMainData({ ...mainData, [name]: value });
  };

  const handleSubmit = (values) => {
    values.preventDefault();
    props.updateFormData("basicDetail", mainData);
    props.setKey("package");
  };

  const { userName, password, customerName, aliasName, address, phoneNo, emailId } = mainData;

  return (
    <>
      <CCardBody>
        <CContainer>
          <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
            <CRow className="pt-20">
              {/* {isUpdate && (
                <>
                  {" "}
                  <CCol xs={6} className="position-relative">
                    <CFormLabel htmlFor="userName">User Name</CFormLabel>
                    <CFormInput type="text" id="userName" name="userName" placeholder="userName" value={userName} onChange={onChangeValue} required />
                  </CCol>
                  <CCol xs={6} className="position-relative">
                    <CFormLabel htmlFor="password">Password</CFormLabel>
                    <CFormInput type="Password" id="password" name="password" placeholder="password" value={password} onChange={onChangeValue} required />
                  </CCol>
                </>
              )} */}
              <CCol xs={6} className="position-relative">
                <CFormLabel htmlFor="userName">Customer Name</CFormLabel>
                <CFormInput type="text" id="customerName" name="customerName" placeholder="Customer Name" value={customerName} onChange={onChangeValue} required />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="password">Alias Name</CFormLabel>
                <CFormInput type="text" id="aliasName" placeholder="Alias Name" name="aliasName" value={aliasName} onChange={onChangeValue} required />
              </CCol>
            </CRow>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="firstName">Address</CFormLabel>
                <CFormInput type="text" id="address" placeholder="Address" name="address" value={address} onChange={onChangeValue} required />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="middleName">Phone No.</CFormLabel>
                <CFormInput type="number" id="phoneNo" placeholder="Phone No." name="phoneNo" value={phoneNo} onChange={onChangeValue} />
              </CCol>
            </CRow>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="lastName">Email Id</CFormLabel>
                <CFormInput type="email" id="emailId" placeholder="Email Id" name="emailId" value={emailId} onChange={onChangeValue} required />
              </CCol>
            </CRow>
            <div className="identification_btn">
              <CButton type="submit" color="primary" className="px-4">
                Next
              </CButton>
            </div>
          </CForm>
        </CContainer>
      </CCardBody>
    </>
  );
};
BasicDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
  prefill: PropTypes.object,
};
export default BasicDetails;
