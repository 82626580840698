import React from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import PropTypes from "prop-types";
import { useReactToPrint } from "react-to-print";
import CustomerTable from "src/components/CompanyAllDetails/customerTable";
import StaffTable from "src/components/CompanyAllDetails/staffTable";
import PackageTable from "src/components/CompanyAllDetails/packageTable"

const CompanyAllDetail = ({
  selectedCompany,
  setShowAccordion,
  setShowSelectAndSearch,
}) => {

  const componentRef = React.useRef();
  const handleCloseAccordian = () => {
    setShowSelectAndSearch(true);
    setShowAccordion(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const _addres = JSON.parse(selectedCompany?.hospitalAddress || "");
  const formattedAddres = `${_addres?.ploatNumber} ${_addres?.city} ${_addres?.state} ${_addres?.zipCode} ${_addres?.country}`

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <div className="close_btn d-flex justify-content-between">
                <div>
                  {selectedCompany?.hospitalName
                    ? selectedCompany?.hospitalName
                    : "--"}{" "}
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-end">
                      <button className='btn btn-primary' onClick={handlePrint}>Download</button>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => handleCloseAccordian()}
                ></button>
              </div>



            </CCardHeader>
            <CCardBody ref={componentRef} id='my-print'>
              <CCard>
                <CCardBody>
                  <CRow>
                    <CCol xs={6}>
                      <label className="label">Company Name : {" "}
                        {selectedCompany?.hospitalName}
                      </label>
                    </CCol>
                    <CCol xs={6}>
                      <label>Company Mobile Number : {" "}
                        {selectedCompany?.hospitalMobileNumber}{" "}
                      </label>
                    </CCol>
                  </CRow>

                  <div className="mt-2"></div>
                  <CRow>
                    <CCol xs={6}>
                      <label className="label">Company Address : {" "}
                        {formattedAddres}
                      </label>
                    </CCol>
                    <CCol xs={6}>
                      <label>Company Email Id: {" "}
                        {selectedCompany?.hospitalEmail}{" "}
                      </label>
                    </CCol>
                  </CRow>

                  <div className="mt-2"></div>
                  <CRow>
                    <CCol xs={6}>
                      <label className="label">Company GST Number : {" "}
                        {selectedCompany?.gstNumber}
                      </label>
                    </CCol>
                    <CCol xs={6}>
                      <label>Company Short ID: {" "}
                        {selectedCompany?.shortName}{" "}
                      </label>
                    </CCol>
                  </CRow>

                  <div className="mt-2"></div>
                  <CRow>
                    <CCol xs={6}>
                      <label className="label">License Start Date : {" "}
                        {selectedCompany?.startDate}
                      </label>
                    </CCol>
                    <CCol xs={6}>
                      <label>License Due Date : {" "}
                        {selectedCompany?.dueDate}{" "}
                      </label>
                    </CCol>
                  </CRow>

                  <div className="mt-2"></div>
                  <CRow>
                    <CCol xs={6}>
                      <label className="label">Company Status : {" "}
                        {selectedCompany?.activeStatus}
                      </label>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardBody>
            <CCardBody>
              <PackageTable schemaName={selectedCompany?.schemaName} />
            </CCardBody>
            <CCardBody>
              <StaffTable schemaName={selectedCompany?.schemaName} />
            </CCardBody>
            <CCardBody>
              <CustomerTable schemaName={selectedCompany?.schemaName} />
            </CCardBody>

          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
CompanyAllDetail.propTypes = {
  setShowAccordion: PropTypes.func.isRequired,
  setShowSelectAndSearch: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
};

export default CompanyAllDetail;
