import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import routes from "../navigations/AppRoutes";
import { CBreadcrumb, CBreadcrumbItem, CButton } from "@coreui/react";

import LoanUpdateModal from "./commonComponents/loanUpdateModal";
import { useSelector } from "react-redux";

import StaffAddAndEditModal from "./commonComponents/staffEditAndAddForm";
import CompanyAddAndUpdateForm from "./Modal/editAndAddForm";
import CustomerAddForm from "src/pages/customer/customerAddForm";

const getBreadcrumbs = (location) => {
  const breadcrumbs = [];
  const pathSegments = location.split("/").filter((segment) => segment !== "");

  pathSegments.reduce((prev, curr) => {
    const currentPath = prev + "/" + curr;
    const route = routes.find((route) => route.path === currentPath);
    if (route) {
      breadcrumbs.push({
        pathname: currentPath,
        name: route.name,
        active: false,
      });
    }
    return currentPath;
  }, "");

  if (breadcrumbs.length > 0) {
    breadcrumbs[breadcrumbs.length - 1].active = true;
  }

  return breadcrumbs;
};

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname;

  const breadcrumbs = getBreadcrumbs(currentLocation);

  const [showUserForm, setShowUserForm] = useState(false);
  const [showStaffForm, setShowStaffForm] = useState(false);
  const [showCompanyForm, setShowCompanyForm] = useState(false);
  const [visibleUser, setVisibleUser] = useState(true);
  const [visibleCompany, setVisibleCompany] = useState(true);
  const [visibleCustomer, setVisibleCustomer] = useState(false);

  const { routeValue } = useSelector((state) => state?.planReducer);

  const toggleForm = () => {
    if (routeValue === "users") {
      setShowUserForm(true);
      setVisibleUser(true);
      setShowCompanyForm(false);
      setShowStaffForm(false);
      setVisibleCustomer(false);
    } else if (routeValue === "loanList") {
      setShowCompanyForm(true);
      setVisibleCompany(true);
      setShowUserForm(false);
      setShowStaffForm(false);
      setVisibleCustomer(false);
    } else if (routeValue === "Staffs") {
      setShowStaffForm(true);
      setVisibleUser(true);
      setShowUserForm(false);
      setShowCompanyForm(false);
      setVisibleCustomer(false);
    } else if (routeValue === "customers") {
      setVisibleCustomer(true);
      setVisibleUser(true);
      setShowUserForm(false);
      setShowCompanyForm(false);
    }
  };

  return (
    <>
      <CBreadcrumb className="m-0 ms-2 d-flex justify-content-between">
        <CBreadcrumbItem href="/dashboard">Home</CBreadcrumbItem>
        {breadcrumbs?.map((breadcrumb, index) => {
          return (
            <CBreadcrumbItem
              {...(breadcrumb.active
                ? { active: true }
                : { href: breadcrumb.pathname })}
              key={index}
            >
              {breadcrumb.name}
            </CBreadcrumbItem>
          );
        })}
      </CBreadcrumb>
      {/* <div className="d-grid gap-2 col-1">
        <CButton onClick={toggleForm} className="w-5">
          Add
        </CButton>
        {showUserForm ? (
          <CompanyAddAndUpdateForm
            visible={visibleUser}
            setVisible={setVisibleUser}
          />
        ) : (
          ""
        )}
        {showCompanyForm ? (
          <LoanUpdateModal
            visible={visibleCompany}
            setVisible={setVisibleCompany}
          />
        ) : (
          ""
        )}
        {showStaffForm ? (
          <StaffAddAndEditModal
            visible={visibleCompany}
            setVisible={setShowStaffForm}
          />
        ) : (
          ""
        )}
        {visibleCustomer ? (
          <CustomerAddForm
            visible={visibleCompany}
            setVisible={setVisibleCustomer}
          />
        ) : (
          ""
        )}
      </div> */}
    </>
  );
};

export default React.memo(AppBreadcrumb);
