import request from "../services/request";
import { API_BASE_URL } from "../services/const";

export const addConsumers = (data) => request(`${API_BASE_URL}/consumers`, {
    method: "POST",
    data,
});

export const getAllConsumers = (data) => request(`${API_BASE_URL}/getallConsumers`, {
    method: "POST",
    data,
});
