import request from "../services/request";
import { API_BASE_URL } from "../services/const";

export const addCompany = (data) => request(`${API_BASE_URL}/hospital`, {
    method: "POST",
    data,
    headers: new Headers({
        "Content-Type": "multipart/form-data",
    }),
});

export const updateCompany = (data, id) => request(`${API_BASE_URL}/hospital/${id}`, {
    method: "PATCH",
    data,
    headers: new Headers({
        "Content-Type": "multipart/form-data",
    }),
});


export const getAllCompanies = () => request(`${API_BASE_URL}/hospitals`, {
    method: "POST",
});