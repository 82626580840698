import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/assets/styles/scss/style.scss";
import Home from "./pages/Home";
import Loading from "./components/loading/index";
// Pages
import Login from "./views/pages/login/Login";
import Register from "./views/pages/register/Register";
import Page404 from "./views/pages/page404/Page404";
import Page500 from "./views/pages/page500/Page500";
import Company from "./pages/company";

const route = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={<Register />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<Home />} />
          <Route exact path="/company" name="Company" element={<Company />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default route;
