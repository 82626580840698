import { all } from "redux-saga/effects";
import planActionWatcher from "./planSagaActions";
import staffActionWatcher from "./staffSagaActions";
import emiActionWatcher from "./emiSagaAction";
import customerActionWatcher from "./customerSagaAction";
import InterestsActionWatcher from './loanListSagaAction';
import hospitalActionWatcher from "./hospitalSagaAction";

export default function* rootSaga() {
  yield all([
    planActionWatcher(),
  hospitalActionWatcher(),
    staffActionWatcher(),
    emiActionWatcher(),
    customerActionWatcher(),
    InterestsActionWatcher(),
    // userActionWatcher(),
  ]);
}
