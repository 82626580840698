import React, { useEffect, useState } from "react";
import { CButton, CButtonGroup, CCard, CCardBody, CCol, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import WidgetsDropdown from "../../views/widgets/WidgetsDropdown";
import { Porvider } from "./providers";
import { getAdminMetaData } from "src/api/allApis";

const Dashboard = () => {
  const { usersCount } = Porvider();
  const [incomeCount, setIncomeCount] = useState(0);
  const [conversionCount, setConversionCount] = useState(0);
  const [sessionCount, setSessionCount] = useState(0);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [adminMeta, setAdminMeta] = useState({});

  const user = sessionStorage.getItem("role");
  const isCompany = user == "admin";

  const isStaff = user == "staff"

  const adminMetaData = async () => {
    const payload = { schemaName: SchemaName };
    const res = await getAdminMetaData(payload);
    setAdminMeta(res?.data);
  };

  useEffect(() => {
    adminMetaData();
  }, []);
  console.log("adminMeta dashboard....",adminMeta);
// suraj and staff
  return (
    <>
      <WidgetsDropdown
        totalCompany={isCompany || isStaff ? adminMeta?.totalCompanyPackages : adminMeta?.hospitalsCount}
        totalPackages={isCompany || isStaff ? adminMeta?.totalCustomers : adminMeta?.packagesCount}
        activeCompany={isCompany || isStaff ? adminMeta?.totalStaff : adminMeta?.activeHospitalsCount}
        inactiveCompany={isCompany || isStaff ? adminMeta?.totalCustomers : adminMeta?.inActiveHospitalsCount}
        totalAmount={adminMeta?.totalPackagesAmount}
      />
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            {isCompany|| isStaff  ? (
              <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Total Sales
              </h4>
              <h4 id="traffic" className="card-title mb-0 text-medium-emphasis">
                {/* {adminMeta?.totalStaff} */}
                {adminMeta?.totalCompanyPackagesAmount}
              </h4>
            </CCol>
            ) : (
              <CCol sm={5}>
                <h4 id="traffic" className="card-title mb-0">
                  Total Collection Amount
                </h4>
                <h4 id="traffic" className="card-title mb-0 text-medium-emphasis">
                  {adminMeta?.totalPackagesAmount}
                </h4>
              </CCol>
            )}           
          </CRow>         
        </CCardBody>        
      </CCard>

  
    </>
  );
};

export default Dashboard;
