import request from "../services/request";
import { API_BASE_URL } from "../services/const";

export const addPackage = (data) => request(`${API_BASE_URL}/packages`, {
    method: "POST",
    data,
});

export const getallPackage = (data) => request(`${API_BASE_URL}/getallPackages`, {
    method: "POST",
    data,
});

export const updatePackage = (id, data) => request(`${API_BASE_URL}/packages/${id}`, {
    method: "PATCH",
    data,
});

export const addPackageList = (data) => request(`${API_BASE_URL}/pack/addPackage`, {
    method: "POST",
    data,
});