import React from 'react'
import { AppContent, AppSidebar, AppHeader, Footer } from '../../components'
import { Navigate } from 'react-router-dom';
import _ from "lodash";

const Home = () => {
    const isLoggedIn = !_.isEmpty(sessionStorage.getItem("token"));

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    {isLoggedIn ? <AppContent /> : <Navigate to="/login"/>}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Home
