import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CFormSelect, CFormInput, CCardHeader, CCol, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import { getallPackage } from "src/api/packageApi";

const PackageTable = (companySchema) => {
    const [showModal, setShowModal] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showAccordion, setShowAccordion] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [companyModel, setCompanyModel] = useState(false);
    const [showAddButton, setShowAddButton] = useState(true);
    const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currItem, setCurrItem] = useState({});
    const [showEdit, setShowEdit] = useState(false);
    const [packageData, setPackageData] = useState([]);

    const { update } = useSelector((state) => state?.customerReducer);

    const SchemaName = companySchema?.schemaName;

    useEffect(() => {
        getAllPackages();
    }, [update]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [packageLimit, setPackageLimit] = useState(0);

    const getAllPackages = () => {
        const payload = { schemaName: SchemaName };
        getallPackage(payload)
            .then((res) => {
                setPackageData(res?.data?.result);
                setPackageLimit(res?.data?.limit);
            })
            .catch((err) => console.log("err", 85));
    };

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = packageData?.slice(indexOfFirstRow, indexOfLastRow);

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    return (
        <>
            <CRow>
                <CCol xs={12}>
                    <CCard className="mb-4">
                        <CCardHeader>
                            <strong>Scheme</strong>
                        </CCardHeader>
                        <div className="search_bar">
                            {showSelectAndSearch && (
                                <div>
                                    <CFormSelect size="sm" className="mb-3" style={{ cursor: "pointer" }} aria-label="Small select example" onChange={(e) => setRowsPerPage(e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                    </CFormSelect>
                                </div>
                            )}

                            <div style={{ display: "flex" }}>
                                {showSelectAndSearch && (
                                    <>
                                        <CFormInput type="text" size="sm" placeholder="Search" aria-label="sm input example" style={{ margin: "0px 0px 16px 0px" }} onChange={handleSearchChange} value={searchInput} />

                                        <span
                                            style={{
                                                border: " 1px solid #d1c5c5",
                                                borderRadius: "4px",
                                                margin: "0px 5px 16px -1px",
                                                padding: "1px 8px 0px 8px",
                                                background: "#ebedef",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <i className="fa fa-search"></i>
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>

                        <CCardBody>
                            <div className={`table-container ${showAccordion ? "hidden" : ""} ${showAddForm ? "hidden" : ""}`}>
                                <CTable color="dark" hover className="">
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell scope="col" className="col-md-1">
                                                S. No.
                                            </CTableHeaderCell>
                                            <CTableHeaderCell scope="col" className="col-md-3">
                                                Scheme Name
                                            </CTableHeaderCell>
                                            <CTableHeaderCell scope="col" className="col-md-3">
                                                Start Date
                                            </CTableHeaderCell>
                                            <CTableHeaderCell scope="col" className="col-md-3">
                                                Due Date
                                            </CTableHeaderCell>
                                            <CTableHeaderCell scope="col" className="col-md-2">
                                                Amount
                                            </CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {currentRows && currentRows?.length > 0
                                            ? currentRows.map((item, id) => {
                                                return (
                                                    <CTableRow key={id}>
                                                        <CTableDataCell >{id + 1}</CTableDataCell>
                                                        <CTableDataCell>{item.packageName}</CTableDataCell>
                                                        <CTableDataCell>{item.startDate}</CTableDataCell>
                                                        <CTableDataCell>{item.dueDate}</CTableDataCell>
                                                        <CTableDataCell>{item.amount}</CTableDataCell>
                                                    </CTableRow>
                                                );
                                            })
                                            : ""}
                                    </CTableBody>
                                </CTable>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        Showing {indexOfFirstRow + 1} to {Math?.min(indexOfLastRow, packageData?.length)} of {packageData?.length} entries
                                    </div>
                                    <div className="pagination-container">
                                        <ReactPaginate
                                            activePage={currentPage}
                                            totalItemsCount={packageData?.length}
                                            itemsCountPerPage={rowsPerPage}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            prevPageText="Previous"
                                            nextPageText="Next"
                                            firstPageText="First"
                                            lastPageText="Last"
                                            innerClass="pagination"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol xs={12}>
                    <div className={`accordion-container ${showAccordion ? "visible" : ""} ${showAddForm ? "visible" : ""}`}></div>
                </CCol>
            </CRow>
        </>
    );
};

export default PackageTable;
