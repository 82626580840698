import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormSelect,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { useForm } from "react-hook-form";
import { errorAlert, successAlert } from "src/utils/Swal";

import { useDispatch, useSelector } from "react-redux";
import {
  adminstratorLogin,
  selectedRoleType,
} from "src/redux/actions/staffAction";

const Login = () => {
  const dispatch = useDispatch();
  const { staffReducer } = useSelector((state) => state);

  const [selectedRole, setSelectedRole] = useState("");

  let navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    dispatch(selectedRoleType(selectedRole));
    sessionStorage.setItem("role", selectedRole);
    if (selectedRole === "super admin") {
      // successAlert("Success", "Successfully logged in");
      // navigate("/dashboard", { replace: true });
      dispatch(adminstratorLogin(data));
    } else if (selectedRole === "admin") {
      // successAlert("Success", "Successfully logged in");
      // navigate("/dashboard", { replace: true });
      dispatch(adminstratorLogin(data));
    } else if (selectedRole === "customer") {
      // successAlert("Success", "Successfully logged in");
      // navigate("/dashboard", { replace: true });
      dispatch(adminstratorLogin(data));
    } else if (selectedRole === "staff") {
      // successAlert("Success", "Successfully logged in");
      // navigate("/dashboard", { replace: true });
      dispatch(adminstratorLogin(data));
    }
  };

  useEffect(() => {
    if (staffReducer?.error === false) {
      if (staffReducer?.data?.status === "success") {
        sessionStorage.setItem("token", staffReducer?.data?.token);
        sessionStorage.setItem(
          "schemaName",
          staffReducer?.data?.data?.schemaName
        );
        sessionStorage.setItem(
          "companyId",
          staffReducer?.data?.data?.companyId
        );
        successAlert("Success", "Successfully logged in");
        navigate("/dashboard", { replace: true });
      }
    } else {
      errorAlert("failed", "some-thing went wrong");
    }
  }, [staffReducer]);

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard style={{ backgroundColor: "#bfc9d9" }} className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: "grid", justifyContent: "center" }}>
                      <h1 className="loginSectionData">Login</h1>
                      <p className=" loginSectionData">Login to your account</p>
                    </div>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="User Name"
                        autoComplete="userName"
                        name="userName"
                        {...register("userName", { required: true })}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        {...register("password", { required: true })}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CFormSelect
                        name="role"
                        {...register("role", { required: true })}
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                      >
                        <option value="">SELECT ROLE</option>
                        <option value="super admin">SUPER ADMIN</option>
                        <option value="admin">ADMIN</option>
                        <option value="staff">STAFF</option>
                        <option value="customer">CUSTOMER</option>
                      </CFormSelect>
                    </CInputGroup>
                    <CRow>
                      <CCol className=" text-right">
                        <CButton type="submit" className="px-4 login-btn">
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className=" text-right">
                        <CButton
                          onClick={() => navigate("")}
                          color="link"
                          className="px-0 pt-3 forgotPassword"
                        >
                          Forgot password ?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;

// import React, { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import {
//   CButton,
//   CCard,
//   CCardBody,
//   CCardGroup,
//   CCol,
//   CContainer,
//   CForm,
//   CFormInput,
//   CInputGroup,
//   CInputGroupText,
//   CRow,
// } from '@coreui/react'
// import CIcon from '@coreui/icons-react'
// import { cilLockLocked, cilUser } from '@coreui/icons'
// import { useForm } from 'react-hook-form'
// import { errorAlert, successAlert } from 'src/utils/Swal'

// const Login = () => {
//   let navigate = useNavigate()
//   const { register, handleSubmit } = useForm()

//   const onSubmit = (data) => {
//     if(data.userName === "demo@gmail.com" && data.Cpassword === "demo")
//     {
//       successAlert("success","successfully login")
//       navigate("/dashboard",{replace:true})
//     }
//   else{
//     errorAlert("failed","bad-credentials")
//   }
//   }

//   useEffect(()=>{

//   },[])

//   return (
//     <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
//       <CContainer>
//         <CRow className="justify-content-center">
//           <CCol md={4}>
//             <CCardGroup>
//               <CCard className="p-4">
//                 <CCardBody>
//                   <CForm onSubmit={handleSubmit(onSubmit)}>
//                     <h1>Login</h1>
//                     <p className="text-medium-emphasis">Login In to your account</p>
//                     <CInputGroup className="mb-3">
//                       <CInputGroupText>
//                         <CIcon icon={cilUser} />
//                       </CInputGroupText>
//                       <CFormInput
//                         placeholder="userName"
//                         autoComplete="userName"
//                         name="userName"
//                         {...register('userName', { required: true })}
//                       />
//                     </CInputGroup>
//                     <CInputGroup className="mb-4">
//                       <CInputGroupText>
//                         <CIcon icon={cilLockLocked} />
//                       </CInputGroupText>
//                       <CFormInput
//                         type="password"
//                         placeholder="Password"
//                         autoComplete="current-password"
//                         name="Cpassword"
//                         {...register('Cpassword', { required: true })}
//                       />
//                     </CInputGroup>
//                     <CRow>
//                       <CCol xs={6}>
//                         <CButton type="submit" color="primary" className="px-4">
//                           Login
//                         </CButton>
//                       </CCol>
//                       <CCol xs={6} className="text-right">
//                         <CButton onClick={() => navigate('')} color="link" className="px-0">
//                           Forgot password?
//                         </CButton>
//                       </CCol>
//                     </CRow>
//                   </CForm>
//                 </CCardBody>
//               </CCard>
//             </CCardGroup>
//           </CCol>
//         </CRow>
//       </CContainer>
//     </div>
//   )
// }

// export default Login
