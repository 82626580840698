import request from "../services/request";
import { API_BASE_URL } from "src/services/const";


  export const addStaff= (data) => request(`${API_BASE_URL}/staffs`, {
    method: "POST",
    data,
  });
  
  export const getAllStaff = (data) =>
    request(`${API_BASE_URL}/getAllStaff`, {
      method: "POST",
      data,
    });

  export const viewStaff = (data) =>
    request(`${API_BASE_URL}/staff/${data.id}`, {
      method: "POST",
      data,
    });


  export const updateStaff = (data,id) =>
    request(`${API_BASE_URL}/staff/${id}`, {
    method: "PATCH",
    data,
  });

//  /1

export const deleteStaff = (data,id) =>
    request(`${API_BASE_URL}/deleteStaff/${id}`, {
      method: "POST",
      data,
    });