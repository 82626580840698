import { ERROR, GET_ALL_COMPANY_SUCCESS, UPDATE_COMPANY_BY_ID_SUCCESS } from "../action_Types";
const initialState = {
  data: null,
  message: "",
  error: false,
  update: false
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true
      };
    case UPDATE_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        error: false,
        update: true
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
