import request from "../services/request";
import { API_BASE_URL } from "../services/const";


export const addStaff = (data) => request(`${API_BASE_URL}/employee`, { method: "POST", data, });

export const getAllCustomers = (data) => request(`${API_BASE_URL}/getallcustomers`, { method: "POST", data, });


export const addCustomer = (data) => request(`${API_BASE_URL}/customers`, {
  method: "POST",
  data,
  headers: new Headers({
    "Content-Type": "multipart/form-data",
  }),
});

export const getAllScehems = (data) => request(`${API_BASE_URL}/schemes`, { method: "POST", data });

export const addScehem = (data) => request(`${API_BASE_URL}/schemes/add`, { method: "POST", data });

export const editScheme = (data) => request(`${API_BASE_URL}/schemes/edit`, { method: "POST", data });

export const deleteScheme = (data) => request(`${API_BASE_URL}/schemes/delete`, { method: "POST", data });

export const getAdminMetaData = (data) => request(`${API_BASE_URL}/metaData/admin`, { method: "POST", data });
