import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Porvider } from "./provider";

const AddDataModel = ({ show, setShow, operation, sendResData }) => {
  const handleClose = () => setShow(false);
  const { staffData, onChangeValue, handleSubmit, errors } = Porvider({ show, setShow, operation, sendResData });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {operation === "addstaff" ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control type="email" placeholder="User Name" required name="userName" value={staffData.userName} onChange={onChangeValue} isInvalid={!!errors.userName} />
                  <Form.Control.Feedback type="invalid">{errors.userName}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label> {/* field */}
                  <Form.Control type="password" placeholder="Your Password" name="password" value={staffData.password} required onChange={onChangeValue} isInvalid={!!errors.password} />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="First Name" name="firstName" value={staffData.firstName} required onChange={onChangeValue} isInvalid={!!errors.firstName} />
              <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label> {/* field */}
              <Form.Control type="text" placeholder="Last Name" name="lastName" value={staffData.lastName} required onChange={onChangeValue} isInvalid={!!errors.lastName} />
              <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Mobile No</Form.Label> {/* field */}
              <Form.Control type="number" placeholder="Mobile Number" name="mobile" value={staffData.mobile} required onChange={onChangeValue} isInvalid={!!errors.mobile} />
              <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label> {/* field */}
              <Form.Control type="email" placeholder="E-mail Address" required name="email" value={staffData.email} onChange={onChangeValue} isInvalid={!!errors.email} />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="exampleSelect">
              <Form.Label>Status</Form.Label>
              <Form.Select name="activeStatus" value={staffData?.activeStatus} required onChange={onChangeValue}>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AddDataModel.propTypes = {
  show: PropTypes.bool.isRequired,
  operation: PropTypes.string.isRequired,
  setShow: PropTypes.func.isRequired,
  sendResData: PropTypes.object.isRequired,
};
export default AddDataModel;
