import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const NomineeGroup = (props = {}) => {
  const { data, mainRef } = props;

  return (
    <div ref={mainRef} className="print_customer" style={{ marginTop: "50%" }}>
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CCard>
                <CCardBody className="background-container">
                  <CRow className="justify-content-center right">
                    <CCol style={{ width: "100%" }} xs={8}>
                      <div className="mt-2"></div>
                      <CRow>
                        <CCol xs={6}>
                          <label style={{ color: "white" }} className="label">
                            Customer Name : {data?.consumerName}
                          </label>
                        </CCol>
                        <CCol xs={6}>
                          <label style={{ color: "white" }}>Mobile Number : {data?.mobileNumber || "-"} </label>
                        </CCol>
                      </CRow>

                      <div className="mt-2"></div>
                      <CRow>
                        <CCol xs={6}>
                          <label style={{ color: "white" }} className="label">
                            Email Id : {data?.email}
                          </label>
                        </CCol>
                        <CCol xs={6}>
                          <label style={{ color: "white" }} className="label">
                            Address : {data?.address}
                          </label>
                        </CCol>
                      </CRow>

                      <div className="mt-2"></div>
                      <CRow>
                        <CCol xs={6}>
                          <label style={{ color: "white" }}>Scheme Name: {data?.packageDetails?.packageName} </label>
                        </CCol>
                      </CRow>

                      <div className="mt-2"></div>
                      <CRow>
                        <CCol xs={6}>
                          <label style={{ color: "white" }}>Scheme Start Date : {data?.packageDetails?.packageStartingData} </label>
                        </CCol>

                        <CCol xs={6}>
                          <label style={{ color: "white" }} className="label">
                            Scheme End Date : {data?.packageDetails?.packageEndData}
                          </label>
                        </CCol>
                      </CRow>

                      <div className="mt-2"></div>

                      <div className="mt-2"></div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};
NomineeGroup.propTypes = {
  formdata: PropTypes.object.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  contentRef: PropTypes.func,
  data: PropTypes.func,
  mainRef: PropTypes.func,
};
export default NomineeGroup;
