import React, { useState } from "react";
import { CCard, CCol, CRow } from "@coreui/react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import BasicDetails from "../customerData/BasicDetails";
import IdentiFicationForm from "../customerData/identificationForm";
import NomineeGroup from "../customerData/nomineeForm";
import Adress from "../customerData/packages";
import "./addForm.css";
import { refreshPage } from "src/redux/actions/customerAction";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { addCustomer } from "src/api/allApis";

const ViewForm = ({ setShowForm, setShow, callBack }) => {
  const [key, setKey] = useState("home");
  const SchemaName = sessionStorage.getItem("schemaName")

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    basicDetail: {},
    customerPackages: {},
    identiFication: {},
    bankDetails: {},
    income: {},
    references: {},
    Nomniee: {},
  });

  const updateFormData = (formName, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: data,
    }));
  };
  const collectAllData = () => {
    const allData = {
      basicDetail: formData.basicDetail,
      customerPackages: formData.customerPackages,
      identiFication: formData.identiFication,
      bankDetails: formData.bankDetails,
      income: formData.income,
      references: formData.references,
    };

    const combinedData = Object.keys(allData).reduce((acc, key) => {
      return { ...acc, ...allData[key] };
    }, {});

    return combinedData;
  };
  const handleFormSubmit = async (event) => {
    const {
      firstName,

      password,
      userName,
    } = formData.basicDetail;

    try {
      const AddCustomerData = new FormData();

      AddCustomerData.append("schemaName", SchemaName);
      AddCustomerData.append("employeeId", 1);
      AddCustomerData.append("userName", userName);
      AddCustomerData.append("password", password);
      AddCustomerData.append("firstName", firstName);

      const res = await addCustomer(AddCustomerData);
      dispatch(refreshPage());
      setShowForm(false);
      callBack && callBack();
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowAddButton = () => {
    setShow(false);
  };

  return (
    <>
      <div className="close_btn">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => handleShowAddButton()}
        ></button>
      </div>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="home" title="Basic Details">
                {BasicDetails && (
                  <BasicDetails
                    formData={formData.basicDetail}
                    updateFormData={updateFormData}
                    setKey={setKey}
                    key={key}
                  />
                )}
              </Tab>
              <Tab
                eventKey="package"
                title="Packages Details"
              // disabled={_.isEmpty(formData.basicDetail)}
              >
                {Adress && (
                  <Adress
                    formData={formData.customerPackages}
                    updateFormData={updateFormData}
                    setKey={setKey}
                    key={key}
                  />
                )}
              </Tab>
              <Tab
                eventKey="Identification"
                title="Billing"
              // disabled={_.isEmpty(formData.customerPackages)}
              >
                {IdentiFicationForm && (
                  <IdentiFicationForm
                    formData={formData.identiFication}
                    updateFormData={updateFormData}
                    setKey={setKey}
                    key={key}
                  />
                )}
              </Tab>
              <Tab
                eventKey="Nominee"
                title="Final Submit"
              // disabled={_.isEmpty(formData.references)}
              >
                {NomineeGroup && (
                  <NomineeGroup
                    handleFormSubmit={(event) => handleFormSubmit(event)}
                    formdata={formData.basicDetail}
                  />
                )}
              </Tab>
            </Tabs>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
ViewForm.propTypes = {
  setShowForm: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  callBack: PropTypes.func,
};

export default ViewForm;
