import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { CButton, CCol, CFormInput, CFormSelect, CRow } from "@coreui/react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { addPackage, updatePackage } from "src/api/packageApi";
import { useDispatch } from "react-redux";
import { refreshPage } from "src/redux/actions/customerAction";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddForm = (props) => {
  const dispatch = useDispatch();

  const initialState = {
    packageName: "",
    duration: "monthly",
    startDate: "",
    dueDate: "",
    amount: "",
  }

  const { intial = {}, edit = false } = props;
  console.log("intial", intial)
  const [packageData, setpackageData] = useState(intial || initialState);
  const [text, setText] = useState('');
  const SchemaName = sessionStorage.getItem("schemaName");

  const handleChange = (value) => {
    setText(value);
  };

  const validationSchema = Yup.object().shape({
    loanType: Yup.string().required("Loan Type is required"),
    rate: Yup.number().required("Rate is required"),
  });

  const handleSubmitPackage = () => {
    if (!edit) {
      const payload = {
        schemaName: SchemaName,
        packageName,
        duration: "monthly",
        startDate,
        dueDate,
        amount,
        packageDetails: text
      }
      addPackage(payload)
        .then(result => {
          props?.setShowAddForm(false)
          dispatch(refreshPage())
        })
        .catch(err => console.log("err", err))
    } else {
      const payload = {
        schemaName: SchemaName,
        packageName,
        duration: "monthly",
        startDate,
        dueDate,
        amount,
        packageDetails: text
      }

      updatePackage(intial.id, payload)
        .then(result => {
          props?.setShowAddForm(false)
          dispatch(refreshPage())
        })
        .catch(err => console.log("err", err))
    }
  };

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setpackageData({ ...packageData, [name]: value });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image',
  ];

  // destructuring
  const {
    packageName,
    duration,
    startDate,
    dueDate,
    amount,
  } = packageData;

  return (
    <>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={handleSubmitPackage}
      >

        <Form className="p-4">
          <CRow style={{ margin: "20px 0" }}>
            <CCol xs={6} className="position-relative">
              <label className="form-label mt-1">Scheme Name</label>
              <Field
                type="test"
                id="packageName"
                name="packageName"
                as={CFormInput}
                floatingClassName="mb-4"
                placeholder="Scheme Name"
                value={packageName}
                onChange={onChangeValue}
              />
              <ErrorMessage name="packageName" component="div" className="text-danger" />
            </CCol>

            <CCol xs={6} className="position-relative">
              <label className="form-label mt-1">Start Date</label>
              <Field
                type="date"
                id="startDate"
                name="startDate"
                as={CFormInput}
                floatingClassName="mb-4"
                // floatingLabel="Start Date"
                placeholder="Start Date"
                value={startDate}
                onChange={onChangeValue}
              />
              <ErrorMessage
                name="startDate"
                component="div"
                className="text-danger"
              />
            </CCol>
          </CRow>

          <CRow style={{ margin: "20px 0" }}>
            <CCol xs={6} className="position-relative">
              <label className="form-label mt-1">Due Date</label>
              <Field
                type="date"
                id="dueDate"
                name="dueDate"
                as={CFormInput}
                floatingClassName="mb-4"
                // floatingLabel="DueDate"
                placeholder="Due Date"
                value={dueDate}
                onChange={onChangeValue}
              />
              <ErrorMessage name="dueDate" component="div" className="text-danger" />
            </CCol>

            <CCol xs={6} className="position-relative">
              <label className="form-label mt-1">Amount</label>
              <Field
                type="number"
                id="amount"
                name="amount"
                as={CFormInput}
                placeholder="Amount "
                value={amount}
                onChange={onChangeValue}
              />
              <ErrorMessage
                name="amount"
                component="div"
                className="text-danger"
              />
            </CCol>
          </CRow>

          <CRow style={{ margin: "20px 0" }}>
            <CCol xs={12} className="position-relative">
              <label className="form-label mt-1">Scheme Details</label>
              <ReactQuill
                value={text}
                onChange={handleChange}
                modules={modules}
                formats={formats}
              />
              <ErrorMessage
                name="packageDetails"
                component="div"
                className="text-danger"
              />
            </CCol>
          </CRow>

          <div className="identification_btn">
            <CButton type="submit" onClick={handleSubmitPackage} className="next_btn">
              {edit ? "Update" : "Submit"}{" "}
            </CButton>
          </div>
        </Form>
      </Formik>
    </>
  );
};
AddForm.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
  intial: PropTypes.object,
  edit: PropTypes.bool,
  setShowAddForm: PropTypes.bool.isRequired,
  setShowSelectAndSearch: PropTypes.bool.isRequired,
  setShowAddButton: PropTypes.bool.isRequired,
  callBack: PropTypes.func,
};
export default AddForm;
