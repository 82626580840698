import React, { useEffect, useState } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormInput,
    CFormSelect,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from "@coreui/react";
import AddForm from "src/pages/customer/addForm";
import ViewForm from "src/pages/customer/addForm";
import CompanyAddAndUpdateForm from "src/components/Modal/editAndAddForm";
import ReactPaginate from "react-js-pagination";
import CompanyAllDetail from "src/pages/company/companyAllDetails";
import { getAllConsumers } from "src/api/customerApi";
import { useNavigate } from "react-router-dom";

const CustomerTable = (companySchema) => {
    const [show, setShow] = useState(false);
    const [ShowAdd, setShowAdd] = useState(false);
    const [operation, setOpearation] = useState("");
    const [showAccordion, setShowAccordion] = useState(false);
    const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState(null);
    const [editLoanData, setEditLoanData] = useState(null);
    const [updateForm, setUpdate] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [customerData, setCustomerData] = useState([])

    const SchemaName = companySchema?.schemaName;;
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getAllCustomer()
    }, [])

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    }

    const getAllCustomer = () => {
        const payload = { schemaName: SchemaName }
        getAllConsumers(payload)
            .then(res => setCustomerData(res?.data))
            .catch(err => console.log("err", err))
    }

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = customerData?.slice(indexOfFirstRow, indexOfLastRow);

    return (
        <div>
            {show && <ViewForm show={show} setShow={setShow} />}
            {ShowAdd ? (
                <AddForm ShowAdd={ShowAdd} setShowAdd={setShowAdd} />
            ) : (
                <CRow>
                    <CCol xs={12}>
                        <CCard className="mb-4">
                            <CCardHeader>
                                <strong>Customer</strong>
                            </CCardHeader>
                            <div className="search_bar">
                                {showSelectAndSearch && (
                                    <div>
                                        <CFormSelect
                                            size="sm"
                                            className="mb-3"
                                            style={{ cursor: "pointer" }}
                                            aria-label="Small select example"
                                            onChange={(e) => setRowsPerPage(e.target.value)}
                                        >
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </CFormSelect>
                                    </div>
                                )}
                                <div style={{ display: "flex" }}>
                                    {showSelectAndSearch && (
                                        <>
                                            <CFormInput
                                                type="text"
                                                size="sm"
                                                placeholder="Search"
                                                aria-label="sm input example"
                                                style={{ margin: "0px 0px 16px 0px" }}
                                                onChange={handleSearchChange}
                                                value={searchInput}
                                            />

                                            <span
                                                style={{
                                                    border: " 1px solid #d1c5c5",
                                                    borderRadius: "4px",
                                                    margin: "0px 5px 16px -1px",
                                                    padding: "1px 8px 0px 8px",
                                                    background: "#ebedef",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <i className="fa fa-search"></i>
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>

                            <CCardBody>
                                <div
                                    className={`table-container ${showAccordion ? "hidden" : ""}`}
                                >
                                    <CTable color="dark" hover className="">
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell scope="col" className="col-md-1">
                                                    S. No.
                                                </CTableHeaderCell>
                                                <CTableHeaderCell scope="col" className="col-md-2">
                                                    Customer Name
                                                </CTableHeaderCell>
                                                <CTableHeaderCell scope="col" className="col-md-2">
                                                    Alias Name
                                                </CTableHeaderCell>
                                                <CTableHeaderCell scope="col" className="col-md-2">
                                                    Address
                                                </CTableHeaderCell>
                                                <CTableHeaderCell scope="col" className="col-md-2">
                                                    Mobile No
                                                </CTableHeaderCell>
                                                <CTableHeaderCell scope="col" className="col-md-2">
                                                    Email
                                                </CTableHeaderCell>
                                                {/* <CTableHeaderCell scope="col" className="col-md-2">
                                                    Action
                                                </CTableHeaderCell> */}
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {currentRows && currentRows?.length > 0 ? (
                                                currentRows?.filter((company) =>
                                                    company?.consumerName
                                                        ?.toLowerCase()
                                                        .includes(searchInput.toLowerCase())
                                                ).map((item, id) => {
                                                    return (
                                                        <CTableRow key={id}>
                                                            <CTableDataCell>
                                                                {id + 1}
                                                            </CTableDataCell>
                                                            <CTableDataCell>
                                                                {item?.consumerName || "--"}
                                                            </CTableDataCell>
                                                            <CTableDataCell>
                                                                {item?.aliasName || "--"}
                                                            </CTableDataCell>
                                                            <CTableDataCell>
                                                                {item?.address || "--"}
                                                            </CTableDataCell>
                                                            <CTableDataCell>
                                                                {item?.mobileNumber || "--"}
                                                            </CTableDataCell>
                                                            <CTableDataCell>
                                                                {item?.email || "--"}
                                                            </CTableDataCell>
                                                            {/* <CTableDataCell className="col-md-6">
                                                                <>
                                                                    <Tooltip text="View">
                                                                        <FontAwesomeIcon
                                                                            role="button"
                                                                            icon={faEye}
                                                                            size="1x"
                                                                            className="viewIcon"
                                                                            onClick={() => handleViewDetails(item)}
                                                                        />
                                                                    </Tooltip>

                                                                    <Tooltip text="Update">
                                                                        <FontAwesomeIcon
                                                                            role="button"
                                                                            icon={faPenToSquare}
                                                                            size="1x"
                                                                            onClick={() => handleEditLoan(item)}
                                                                            className="px-3 updateIcon"
                                                                        />
                                                                    </Tooltip>

                                                                    <Tooltip text="Delete">
                                                                        <FontAwesomeIcon
                                                                            onClick={() => handleDeleteClick(item)}
                                                                            role="button"
                                                                            icon={faTrash}
                                                                            size="1x"
                                                                            className="deleteIcon"
                                                                        />
                                                                    </Tooltip>
                                                                </>
                                                            </CTableDataCell> */}
                                                        </CTableRow>
                                                    )
                                                })) : (
                                                <CTableDataCell
                                                    colSpan={7}
                                                    style={{
                                                        textAlign: "center",
                                                        color: "red !important",
                                                    }}
                                                >
                                                    No Record Found!
                                                </CTableDataCell>
                                            )}
                                        </CTableBody>
                                    </CTable>
                                    {model === "update" ? (
                                        <CompanyAddAndUpdateForm
                                            setVisible={setModel}
                                            visible={model}
                                            loanData={editLoanData}
                                            updateForm={updateForm}
                                        />
                                    ) : (
                                        ""
                                    )}

                                    {model === "add" ? (
                                        <CompanyAddAndUpdateForm
                                            // visible={visibleUser}
                                            // setVisible={setVisibleUser}
                                            setVisible={setModel}
                                            visible={model}
                                        />
                                    ) : (
                                        ""
                                    )}

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >

                                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                                            Showing {indexOfFirstRow + 1} to{" "}
                                            {Math?.min(indexOfLastRow, customerData?.length)} of{" "}
                                            {customerData?.length} entries
                                        </div>

                                        <div className="pagination-container">
                                            <ReactPaginate
                                                activePage={currentPage}
                                                itemsCountPerPage={rowsPerPage}
                                                totalItemsCount={customerData?.length}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                prevPageText="Previous"
                                                nextPageText="Next"
                                                firstPageText="First"
                                                lastPageText="Last"
                                                innerClass="pagination"
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        </div>
                                    </div>

                                </div>

                                {showAccordion && (
                                    <CompanyAllDetail
                                        selectedCompany={selectedCompany}
                                        setShowAccordion={setShowAccordion}
                                        setShowSelectAndSearch={setShowSelectAndSearch}
                                    />
                                )}
                            </CCardBody>
                        </CCard>
                    </CCol>

                    <CCol xs={12}>
                        <div
                            className={`accordion-container ${showAccordion ? "visible" : ""
                                }`}
                        ></div>
                    </CCol>
                </CRow>
            )}
        </div>
    );
};

export default CustomerTable;
