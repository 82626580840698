import { combineReducers } from "redux";
import companyReducer from "./companyReducer";
import planReducer from "./planReducers";
import staffReducer from "./staffReducer";
import customerReducer from "./customerReducer";
import emiReducer from "./emiReducer";
import interestsReducer from "./loanListReducer";
const rootReducer = combineReducers({
  companyReducer,
  planReducer,
  staffReducer,
  customerReducer,
  emiReducer,
  interestsReducer,
});
export default rootReducer;
