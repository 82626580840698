import React, { useState } from "react";
import { CModal, CButton, CModalBody, CCol, CContainer, CForm, CModalHeader, CModalTitle, CRow, CFormInput, CSwitch, CFormSwitch } from "@coreui/react";
import PropTypes from "prop-types";
import { addScehem, editScheme } from "src/api/allApis";
import _ from "lodash";

const initialValues = {
  schemename: "",
  amount: "",
  limit: "",
  duration_months: "",
  active: "",
};

const CompanyAddAndUpdateForm = ({ visible, setVisible, loanData, updateForm, refresh, isUpdate, company }) => {
  const [companyData, setCompanyData] = useState(company || initialValues);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    if (name === "active") {
      setCompanyData({ ...companyData, [name]: value == "true" ? "false" : "true" });
      console.log(active, value == "true" ? "false" : "true", value);
      return;
    }
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { schemename, amount, limit, duration_months, active = true } = companyData;

    const payload = {
      schemename,
      amount: parseInt(amount),
      limit: parseInt(limit),
      duration_months: parseInt(duration_months),
      role: "super admin",
    };

    if (isUpdate) {
      payload.id = company?.id;
      payload.active = active == "true" ? true : false;
    }
    try {
      if (isUpdate) {
        editScheme(payload)
          .then((res) => {
            if (res.status === "success") {
              setVisible(false);
              refresh();
            }
          })
          .catch((err) => console.log("err 55", err));
        return;
      }

      addScehem(payload)
        .then((res) => {
          if (res.status === "success") {
            setVisible(false);
            refresh();
          }
        })
        .catch((err) => console.log("err 55", err));
    } catch (error) {
      console.log("error 57", error);
    }
  };

  const { schemename, amount, limit, duration_months, active } = companyData;

  return (
    <CModal backdrop="static" visible={visible} onClose={() => setVisible(false)} aria-labelledby="StaticBackdropExampleLabel" size="lg">
      <CModalHeader>
        <CModalTitle id="StaticBackdropExampleLabel">{updateForm ? "Edit Plan" : "Add Plan"}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer>
          {" "}
          <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="schemename" className="mb-1">
                    Plan Name
                  </label>
                  <CFormInput id="schemename" type="text" name="schemename" value={schemename} onChange={handleChangeValue} required />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="amount" className="mb-1">
                    Amount
                  </label>
                  <CFormInput id="amount" type="number" name="amount" value={amount} onChange={handleChangeValue} required />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="limit" className="mb-1">
                    Limit
                  </label>
                  <CFormInput id="limit" type="number" name="limit" value={limit} onChange={handleChangeValue} required />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="mb-4">
                  <label htmlFor="duration_months" className="mb-1">
                    Duration (Months)
                  </label>
                  <CFormInput id="duration_months" type="number" name="duration_months" value={duration_months} onChange={handleChangeValue} required />
                </div>
              </CCol>
              {isUpdate && (
                <CCol xs={6}>
                  <CFormSwitch label="Active" id="active" name="active" checked={active == "true" ? true : false} value={active == "true" ? true : false} onChange={handleChangeValue} />
                </CCol>
              )}
            </CRow>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CButton type="submit" size="sm" color="primary">
                Submit
              </CButton>
            </div>
          </CForm>
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

CompanyAddAndUpdateForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  loanData: PropTypes.object,
  updateForm: PropTypes.bool,
  refresh: PropTypes.func,
  isUpdate: PropTypes.bool,
  company: PropTypes.object,
};

export default CompanyAddAndUpdateForm;
