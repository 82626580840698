import React, { useState } from "react";
import { CCard, CCol, CRow } from "@coreui/react";
import PropTypes from "prop-types";
import AddForm from "../../../components/packageAddForm";

const PackageAddForm = ({
  setShowAddForm,
  setShowAddButton,
  setShowSelectAndSearch,
  intial,
  edit,
  callBack,
}) => {

  const [key, setKey] = useState("home");
  const SchemaName = sessionStorage.getItem("schemaName")

  const [formData, setFormData] = useState({
    loanType: '',
    rate: '',
    schemaName: SchemaName,
  });


  const updateFormData = (formName, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: data,
    }));
  };

  const handleShowAddButton = () => {
    setShowSelectAndSearch(true);
    setShowAddButton(true);
    setShowAddForm(false);
  };

  return (
    <>
      <div className="close_btn force-left-align">
        <strong >Add Package</strong>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => handleShowAddButton()}
        ></button>
      </div>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            {AddForm && <AddForm
              formData={formData.basicDetail}
              updateFormData={updateFormData}
              setKey={setKey}
              setShowAddButton={setShowAddButton}
              setShowAddForm={setShowAddForm}
              setShowSelectAndSearch={setShowSelectAndSearch}
              key={key}
              intial={intial}
              edit={edit}
              callBack={callBack}
            />
            }
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
PackageAddForm.propTypes = {
  setShowAddForm: PropTypes.func.isRequired,
  setShowAddButton: PropTypes.func.isRequired,
  setShowSelectAndSearch: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  intial: PropTypes.object,
  edit: PropTypes.bool,
  callBack: PropTypes.func
};

export default PackageAddForm;
