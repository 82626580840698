import {
  ERROR,
  GET_ALL_USER_SUCCESS,
  ADD_USER_DATA_SUCCESS,
  REFRESH_PAGE,
} from "../action_Types";

const initialState = {
  data: null,
  message: "",
  error: false,
  update : false 
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
      };

    case ADD_USER_DATA_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
      };
    case REFRESH_PAGE:
      return {
        ...state,
        update: true,
        error: false,
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default customerReducer;
