import React, { useEffect, useState } from "react";
import {
  CModal,
  CButton,
  CModalBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CModalHeader,
  CModalTitle,
  CRow, // Add CModalHeader import
} from "@coreui/react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { addPlan, getAllPlan, updatePlan } from "src/redux/actions/planAction";
import { successAlert } from "src/utils/Swal";

const LoanUpdateModal = ({ visible, setVisible, loanData, updateForm }) => {
  const dispatch = useDispatch();
  const [emiDetails, setEmiDetails] = useState(false);
  const handleEmiCalculation = () => {
    setEmiDetails(true);
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (updateForm) {

      const updatedData = {
        id: loanData.id,
        planAmount: data.planAmount,
        planName: data.planName,
        planType: data.planType,
        recovery: data.recovery,

        noOfAdvanceInstallments: data.noOfAdvanceInstallments,
        // fileCharge: data.fileCharge,
        fileCharge: data.fileCharge,
        processingFeeChargeInclude: data.processingFeeChargeInclude,
        status: data.status === "true",

        numberOfPayments: data.numberOfPayments,
        paidAmount: data.paidAmount,
        processingfee: data.processingfee,
        processingFeeInAmount: data.processingFeeInAmount,
      };
      const sendData = {
        id: loanData.id,
        data: updatedData
      }
      dispatch(updatePlan(sendData));
      setVisible(false);
      successAlert("Success", "Plan successfully updated");
      dispatch(getAllPlan());
      reset();
    } else {
      const capturedData = {
        planAmount: data.planAmount,
        planName: data.planName,
        planType: data.planType,
        recovery: data.recovery,

        noOfAdvanceInstallments: data.noOfAdvanceInstallments,
        fileCharge: data.fileCharge,
        processingFeeChargeInclude: data.processingFeeChargeInclude,
        status: data.status === "true",
        numberOfPayments: data.numberOfPayments,
        paidAmount: data.paidAmount,
        processingFee: data.processingFee,
        processingFeeInAmount: data.processingFeeInAmount,
      };
      dispatch(addPlan(capturedData));
      setVisible(false);
      successAlert("Success", "New plan successfully added");
      dispatch(getAllPlan());
      reset();
    }
  };

  useEffect(() => {
    if (loanData) {
      setValue("planName", loanData.planName);
      setValue("planAmount", loanData.planAmount);
      setValue("recovery", loanData.recovery);
      setValue("planType", loanData.planType);

      setValue("noOfAdvanceInstallments", loanData.noOfAdvanceInstallments);
      setValue("fileCharge", loanData.fileCharge);
      setValue(
        "processingFeeChargeInclude",
        loanData.processingFeeChargeInclude
      );
      setValue("status", loanData.status);
      setValue("numberOfPayments", loanData.numberOfPayments);
      setValue("paidAmount", loanData.paidAmount);
      setValue("processingFee", loanData.processingFee);
      setValue("processingFeeInAmount", loanData.processingFeeInAmount);
    }
  }, [loanData]);

  return (
    <>
      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel"
        size="lg"
      >
        <CModalHeader>
          <CModalTitle id="StaticBackdropExampleLabel">
            {" "}
            {updateForm ? "Edit Plan" : "Add Plan"}{" "}
          </CModalTitle>
        </CModalHeader>

        <CModalBody>
          <CContainer>
            <CForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <CRow style={{ margin: "20px 0" }}>
                <CCol xs={6}>
                  <CFormSelect
                    className="mb-4 custom-select"
                    id="planType"
                    name="planType"
                    required
                    {...register("planType", { required: true })}
                    defaultValue={updateForm ? loanData.planType : ""}
                  >
                    <option value="" disabled>
                      Select Loan Plan
                    </option>
                    <option value="normal">Normal Plan</option>
                    <option value="reduced">Normal Reduced Plan</option>
                    <option value="special">Special Plan</option>
                    <option value="custom">Custom Plan</option>
                    <option value="manual">Manual Plan</option>
                  </CFormSelect>
                </CCol>
                <CCol xs={6}>
                  <CFormSelect
                    // size="md"
                    className="mb-4 custom-select"
                    id="Recovery"
                    defaultValue={updateForm ? loanData.recovery : ""}
                    name="recovery"
                    required
                    {...register("recovery", { required: true })}
                  >
                    <option value="" disabled>
                      Recovery Type
                    </option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="biWeekly">BiWeekly</option>
                    <option value="halfMonth">HalfMonth</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="oneTime">OntTime</option>
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow style={{ margin: "20px 0" }}>
                <CCol xs={6}>
                  <CFormSelect
                    // size="md"
                    className="mb-4 custom-select"
                    id="noOfAdvanceInstallments"
                    defaultValue={
                      updateForm ? loanData.noOfAdvanceInstallments : ""
                    }
                    name="noOfAdvanceInstallments"
                    type="number"
                    required
                    {...register("noOfAdvanceInstallments", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  >
                    <option value="" disabled>
                      No of Advance InstallMent
                    </option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </CFormSelect>
                </CCol>
                <CCol xs={6}>
                  <CFormSelect
                    // size="md"
                    className="mb-4 custom-select"
                    id="fileCharge"
                    defaultValue={updateForm ? loanData.fileCharge : ""}
                    name="fileCharge"
                    required
                    {...register("fileCharge", {
                      required: true,
                    })}
                  >
                    <option value="" disabled>
                      File Charge Include
                    </option>

                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow style={{ margin: "20px 0" }}>
                <CCol xs={6}>
                  <CFormSelect
                    // size="md"
                    className="mb-4 custom-select"
                    id="processingFeeChargeInclude"
                    defaultValue={
                      updateForm ? loanData.processingFeeChargeInclude : ""
                    }
                    name="processingFeeChargeInclude"
                    required
                    type="number"
                    {...register("processingFeeChargeInclude", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  >
                    <option value="" disabled>
                      Processing Fee Charge include
                    </option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </CFormSelect>
                </CCol>
                <CCol xs={6}>
                  <CFormSelect
                    // size="md"
                    className="mb-4 custom-select"
                    id="status"
                    defaultValue={updateForm ? loanData.status : ""}
                    name="status"
                    required
                    {...register("status", { required: true })}
                  >
                    <option value="" disabled>
                      Select Status
                    </option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow style={{ margin: "20px 0" }}>
                <CCol xs={6}>
                  <CFormInput
                    type="text"
                    placeholder="Plan Name"
                    name="planName"
                    floatingClassName="mb-3"
                    floatingLabel="Plan Name"
                    required
                    defaultValue={loanData ? loanData.planName : ""}
                    {...register("planName", { required: true })}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormInput
                    type="number"
                    placeholder="Plan Amount"
                    name="planAmount"
                    floatingClassName="mb-3"
                    floatingLabel="Plan Amount"
                    required
                    defaultValue={loanData ? loanData.planAmount : ""}
                    {...register("planAmount", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                </CCol>
              </CRow>
              <CRow style={{ margin: "20px 0" }}>
                <CCol xs={6}>
                  <CFormInput
                    type="number"
                    placeholder="Number Of Payments"
                    name="numberOfPayments"
                    floatingClassName="mb-3"
                    floatingLabel="Number Of Payments"
                    required
                    defaultValue={loanData ? loanData.numberOfPayments : ""}
                    {...register("numberOfPayments", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormInput
                    type="number"
                    placeholder="Paid Amount"
                    name="paidAmount"
                    floatingClassName="mb-3"
                    floatingLabel="Paid Amount"
                    // required
                    // disabled
                    defaultValue={loanData ? loanData.paidAmount : ""}
                    {...register("paidAmount", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                </CCol>
              </CRow>
              <CRow style={{ margin: "20px 0" }}>
                <CCol xs={6}>
                  <CFormInput
                    type="number"
                    placeholder="Processing Fee in Percentage (%)"
                    name="processingFee"
                    floatingClassName="mb-3"
                    floatingLabel="Processing Fee in Percentage"
                    required
                    defaultValue={loanData ? loanData.processingFee : ""}
                    {...register("processingFee", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormInput
                    type="number"
                    placeholder="Processing Fee in Amount"
                    name="processingFeeInAmount"
                    floatingClassName="mb-3"
                    floatingLabel="Processing Fee in Amount"
                    // required
                    // disabled
                    defaultValue={
                      loanData ? loanData.processingFeeInAmount : ""
                    }
                    {...register("processingFeeInAmount", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                </CCol>
              </CRow>
              <CRow style={{ margin: "20px 0" }} className='d-flex justify-content-center '>

                <CCol xs={7} >
                  <CRow>
                    <CCol xs={3}>
                      <CButton type="submit" color="primary" className="px-4">
                        {updateForm ? "Save" : "Add"}
                      </CButton>
                    </CCol>
                    <CCol xs={5}>
                      <CButton
                        onClick={() => handleEmiCalculation()}
                        color="primary"
                        className="px-4"
                        style={{ backgroundColor: "green" }}
                      >
                        CalcuLate EMI
                      </CButton>
                    </CCol>
                    <CCol xs={2}>
                      <CButton
                        type="submit"
                        color="primary"
                        className="px-4"
                        style={{ backgroundColor: "red" }}
                      >
                        Cancel
                      </CButton>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CForm>
          </CContainer>
        </CModalBody>
      </CModal>
    </>
  );
};

LoanUpdateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  updateForm: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  loanData: PropTypes.object,
};

export default LoanUpdateModal;
