import {
  GET_ALL_EMI_SUCCESS,
  ERROR,
  ADD_EMI_DATA_SUCCESS,
  // UPDATE_EMI_BY_ID_SUCCESS,


  SHOW_LOAN_SUMMARY_SUCCESS,
} from "../action_Types";

const initialState = {
  data: null,
  message: "",
  error: false,
  loanSummaryData: null,
  routeValue: "",
};

const emiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMI_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
      };

    case ADD_EMI_DATA_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
      };
    case SHOW_LOAN_SUMMARY_SUCCESS:
      return {
        ...state,
        loanSummaryData: action.payload,
        update: true,
        error: false,
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default emiReducer;
