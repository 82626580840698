import { CButton, CCard, CCardBody, CCol, CRow } from "@coreui/react";
import React, { useState } from "react";
import PropTypes from "prop-types";

const NomineeGroup = (props) => {
  const handleSubmit = () => {
    props.handleFormSubmit();
  };

  const { isUpdate = false } = props || {};

  const { formdata } = props;
  const { basicDetail, customerPackages, identiFication } = formdata;

  return (
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CCard>
              <CCardBody>
                <CRow className="justify-content-center">
                  <CCol xs={8}>
                    <CRow>
                      <CCol xs={6}>
                        <label className="label">Billing Amount : {identiFication?.billingAmount}</label>
                      </CCol>
                    </CRow>

                    <div className="mt-2"></div>
                    {isUpdate && (
                      <CRow>
                        <CCol xs={6}>
                          <label className="label">User Name : {basicDetail?.userName}</label>
                        </CCol>
                        <CCol xs={6}>
                          <label>Password : {basicDetail?.password} </label>
                        </CCol>
                      </CRow>
                    )}

                    <div className="mt-2"></div>
                    <CRow>
                      <CCol xs={6}>
                        <label className="label">Customer Name : {basicDetail?.customerName}</label>
                      </CCol>
                      <CCol xs={6}>
                        <label>Mobile Number : {basicDetail?.phoneNo} </label>
                      </CCol>
                    </CRow>

                    <div className="mt-2"></div>
                    <CRow>
                      <CCol xs={6}>
                        <label className="label">Email Id : {basicDetail?.emailId}</label>
                      </CCol>
                      <CCol xs={6}>
                        <label className="label">Address : {basicDetail?.address}</label>
                      </CCol>
                    </CRow>

                    <div className="mt-2"></div>
                    <CRow>
                      <CCol xs={6}>
                        <label>Scheme Name: {customerPackages?.packageName} </label>
                      </CCol>

                      <CCol xs={6}>
                        <label className="label">Package Amount : {customerPackages?.packageAmount}</label>
                      </CCol>
                    </CRow>

                    <div className="mt-2"></div>
                    <CRow>
                      <CCol xs={6}>
                        <label>Package Start Date : {customerPackages?.packageStartingData} </label>
                      </CCol>

                      <CCol xs={6}>
                        <label className="label">Package End Date : {customerPackages?.packageEndData}</label>
                      </CCol>
                    </CRow>

                    <div className="mt-2"></div>

                    <div className="mt-2"></div>
                    <CRow>
                      <CCol xs={6}>
                        <label>Payment Date : {identiFication?.paymentDate} </label>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <div className="identification_btn">
              <CButton style={{ margin: "0px -9px 18px 100px" }} className="next_btn" onClick={handleSubmit}>
                Submit Form{" "}
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
NomineeGroup.propTypes = {
  formdata: PropTypes.object.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  // emiList: PropTypes.array.isRequired,
};
export default NomineeGroup;
