import {
  GET_ALL_USER,
  ADD_USER_DATA,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
  UPDATE_CUSTOMER_STATUS,
  UPLOAD_SANCTION_LETTER,
  REFRESH_PAGE,
} from "../action_Types";

export const getAllUser = () => {
  return {
    type: GET_ALL_USER,
  };
};

export const addUser = (data) => {
  return {
    type: ADD_USER_DATA,
    payload: data,
  };
};
export const deleteUser = (id) => {
  return {
    type: DELETE_USER_BY_ID,
    payload: id,
  };
};

export const updateUser = (id) => {
  return {
    type: UPDATE_USER_BY_ID,
    payload: id,
  };
};
export const updateCompanyStatus = (data) => {
 
  return {
    type: UPDATE_CUSTOMER_STATUS,
    payload: data,
  };
};
export const uploadSanctionLetter = (data) => {
  return {
    type: UPLOAD_SANCTION_LETTER,
    payload: data,
  };
};
export const refreshPage = () => {
  return {
    type: REFRESH_PAGE,
   
  };
};
