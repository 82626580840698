import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  ERROR,
  GET_ALL_COMPANY,
  GET_ALL_COMPANY_SUCCESS,
  ADD_COMPANY_DATA,
  ADD_COMPANY_DATA_SUCCESS,
  DELETE_COMPANY_BY_ID,
  DELETE_COMPANY_BY_ID_SUCCESS,
  UPDATE_COMPANY_BY_ID,
  UPDATE_COMPANY_BY_ID_SUCCESS,
  DELETE_LOAN_BY_ID
} from "../action_Types";

function* allHospitals() {
  try {
    const response = yield call(authInstance.post, "hospitals");

    yield put({
      type: GET_ALL_COMPANY_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addCompany(action) {

  try {
    const response = yield call(authInstance.post, "company", action.payload);

    yield put({
      type: ADD_COMPANY_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteCompany(action) {
  const { itemId, itemSchemaName } = action.payload
  try {
    const response = yield call(
      authInstance.post,
      `deleteCompany/${itemId}`, { schemaName: itemSchemaName }
    );

    yield put({
      type: DELETE_COMPANY_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}


function* deleteLoan(action) {
  try {
    const { selectedItemId } = action.payload;
    const response = yield call(
      authInstance.post,
      `deleteInterest/${selectedItemId}`, action.payload
    );
    yield put({
      type: DELETE_COMPANY_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateHospitals(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `company/${id}`, data);
    yield put({
      type: UPDATE_COMPANY_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* hospitalActionWatcher() {
  yield takeLatest(ADD_COMPANY_DATA, addCompany);
  yield takeLatest(GET_ALL_COMPANY, allHospitals);
  yield takeLatest(DELETE_COMPANY_BY_ID, deleteCompany);
  yield takeLatest(DELETE_LOAN_BY_ID, deleteLoan);
  yield takeLatest(UPDATE_COMPANY_BY_ID, updateHospitals);
}
