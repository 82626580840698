import React, { useState } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import BasicDetails from "../customerData/BasicDetails";
import NomineeGroup from "../customerData/nomineeForm";
import Adress from "../customerData/packages";
import "./addForm.css";
import _ from "lodash";
import { addConsumers } from "src/api/customerApi";
import { useNavigate } from "react-router-dom";

const ViewForm = ({ setShowAdd, loanData, isUpdate }) => {
  const [key, setKey] = useState("home");
  const SchemaName = sessionStorage.getItem("schemaName")
  const [formData, setFormData] = useState({
    basicDetail: {},
    customerPackages: {},
    identiFication: {},
  });
  const naviate = useNavigate();
  const updateFormData = (formName, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: data,
    }));
  };

  const handleFormSubmit = async (event) => {
    const {
      userName,
      password,
      address,
      aliasName,
      customerName,
      emailId,
      phoneNo,
    } = formData.basicDetail;

    try {
      const payload = {
        schemaName: SchemaName,
        userName: emailId,
        password: phoneNo,
        consumerName: customerName,
        aliasName,
        mobileNumber: phoneNo,
        address,
        email: emailId,
        packageDetails: formData.customerPackages,
        invoiceDetails: formData.identiFication
      }
      addConsumers(payload)
        .then((res) => {
          naviate("/preview-data", { state: { data: res?.data } })
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowAddButton = () => {
    setShowAdd(false);
  };

  return (
    <>
      <CCard>
        <CCardHeader>
          <div className="close_btn force-left-align">
            <strong >Add Basic Details</strong>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleShowAddButton()}
            ></button>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardBody>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="home" title="Basic Details">
                      {BasicDetails && (
                        <BasicDetails
                          prefill={loanData}
                          formData={formData.basicDetail}
                          updateFormData={updateFormData}
                          setKey={setKey}
                          key={key}
                          isUpdate={isUpdate}
                        />
                      )}
                    </Tab>
                    <Tab
                      eventKey="package"
                      title="Packages Details"
                    // disabled={_.isEmpty(formData.basicDetail)}
                    >
                      {Adress && (
                        <Adress
                          prefill={loanData}
                          formData={formData.customerPackages}
                          updateFormData={updateFormData}
                          setKey={setKey}
                          key={key}
                        />
                      )}
                    </Tab>
                    <Tab
                      eventKey="Nominee"
                      title="View Details"
                    // disabled={_.isEmpty(formData.references)}
                    >
                      {NomineeGroup && (
                        <NomineeGroup
                          handleFormSubmit={(event) => handleFormSubmit(event)}
                          formdata={formData}
                          updateFormData={updateFormData}
                          isUpdate={isUpdate}
                        />
                      )}
                    </Tab>
                  </Tabs>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};
ViewForm.propTypes = {
  setShowAddForm: PropTypes.func.isRequired,
  setShowAdd: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  callBack: PropTypes.func,
  loanData: PropTypes.object,
  isUpdate: PropTypes.bool
};

export default ViewForm;
