import React from "react";
import CIcon from "@coreui/icons-react";
import { CNavGroup, CNavItem } from "@coreui/react";
import { cilSpeedometer } from "@coreui/icons";
import PreviewData from "src/pages/previewData";

const Menu = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Companies",
    to: "/user",
    showOnlyForRole: "super admin",
    items: [
      {
        component: CNavItem,
        name: "Companies",
        to: "/companies",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Plans",
    to: "/schemes",
    showOnlyForRole: "super admin",
    items: [
      {
        component: CNavItem,
        name: "Plan",
        to: "/schemes",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "SCHEME",
    to: "/packageList",
    showOnlyForRole: "admin",
    items: [
      {
        component: CNavItem,
        name: "SCHEME",
        to: "/packageList",
      },

    ],
  },

  {
    component: CNavGroup,
    name: "STAFF",
    to: "/staff",
    showOnlyForRole: "admin",
    items: [
      {
        component: CNavItem,
        name: "STAFF SECTION",
        to: "/staff",
      },
    ],
  },

  // {
  //   component: CNavGroup,
  //   name: "STAFF",
  //   to: "/staff",
  //   showOnlyForRole: "staff",
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "STAFF SECTION",
  //       to: "/staff",
  //     },
  //   ],
  // },

  {
    component: CNavGroup,
    name: "CUSTOMER",
    to: "/customer",
    showOnlyForRole: "admin",
    items: [
      {
        component: CNavItem,
        name: "CUSTOMER SECTION",
        to: "/customer",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "CUSTOMER",
    to: "/customer",
    showOnlyForRole: "staff",
    items: [
      {
        component: CNavItem,
        name: "CUSTOMER SECTION",
        to: "/customer",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "CUSTOMER",
    to: "/customer",
    showOnlyForRole: "customer",
    items: [
      {
        component: CNavItem,
        name: "CUSTOMER SECTION",
        to: "/customer",
      },
    ],
  },
];

export default Menu;
