export const GET_ALL_PLAN = "GET_ALL_PLAN";
export const GET_ALL_PLAN_SUCCESS = "GET_ALL_PLAN_SUCCESS";
export const ERROR = "ERROR";
export const SET_ERROR = "SET_ERROR";
export const ADD_PLAN_DATA = "ADD_PLAN_DATA";
export const ADD_PLAN_DATA_SUCCESS = "ADD_PLAN_DATA_SUCCESS";
export const DELETE_PLAN_BY_ID = "DELETE_PLAN_BY_ID";
export const DELETE_PLAN_BY_ID_SUCCESS = "DELETE_PLAN_BY_ID_SUCCESS";
export const UPDATE_PLAN_BY_ID = "UPDATE_PLAN_BY_ID";
export const UPDATE_PLAN_BY_ID_SUCCESS = "UPDATE_PLAN_BY_ID_SUCCESS";

// ================= Company related Actions ==============

export const GET_ALL_COMPANY = "GET_ALL_COMPANY";
export const GET_ALL_COMPANY_SUCCESS = "GET_ALL_COMPANY_SUCCESS";
export const ADD_COMPANY_DATA = "ADD_COMPANY_DATA";
export const ADD_COMPANY_DATA_SUCCESS = "ADD_COMPANY_DATA_SUCCESS";
export const DELETE_COMPANY_BY_ID = "DELETE_COMPANY_BY_ID";
export const DELETE_LOAN_BY_ID = "DELETE_LOAN_BY_ID";
export const DELETE_COMPANY_BY_ID_SUCCESS = "DELETE_COMPANY_BY_ID_SUCCESS";
export const UPDATE_COMPANY_BY_ID = "UPDATE_COMPANY_BY_ID";
export const UPDATE_COMPANY_BY_ID_SUCCESS = "UPDATE_COMPANY_BY_ID_SUCCESS";
export const UPDATE_CUSTOMER_STATUS = "UPDATE_CUSTOMER_STATUS";
export const UPDATE_CUSTOMER_STATUS_SUCCESS = "UPDATE_CUSTOMER_STATUS_SUCCESS";

// ================================ common actions ===================================
export const SET_ROUTE_VALUE = "SET_ROUTE_VALUE";
export const STAFF_LOGIN = "STAFF_LOGIN";
export const STAFF_LOGIN_SUCCESS = "STAFF_LOGIN_SUCCESS";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMINISTRAOR_LOGIN = "ADMINISTRAOR_LOGIN";
export const ADMINISTRAOR_LOGIN_SUCCESS = "ADMINISTRAOR_LOGIN_SUCCESS";
export const SELECTEDROLE = "SELECTEDROLE"

// ==================================  staff Actions ==================================================
export const GET_ALL_STAFFS = "GET_ALL_STAFFS";
export const GET_ALL_STAFFS_SUCCESS = "GET_ALL_STAFFS_SUCCESS";
export const ADD_STAFFS_DATA = "ADD_STAFFS_DATA";
export const EDIT_STAFFS_DATA = "EDIT_STAFFS_DATA";
export const ADD_STAFFS_DATA_SUCCESS = "ADD_STAFFS_DATA_SUCCESS";
export const DELETE_STAFFS_BY_ID = "DELETE_STAFFS_BY_ID";
export const DELETE_STAFFS_BY_ID_SUCCESS = "DELETE_STAFFS_BY_ID_SUCCESS";
export const UPDATE_STAFFS_BY_ID = "UPDATE_STAFFS_BY_ID";
export const UPDATE_STAFFS_BY_ID_SUCCESS = "UPDATE_STAFFS_BY_ID_SUCCESS";
export const SET_SCHEMANAME = "SET_SCHEMANAME"
// =========================================== emi Actions ==========================================================
export const GET_ALL_EMI = "GET_ALL_EMI";
export const GET_ALL_EMI_SUCCESS = "GET_ALL_EMI_SUCCESS";
export const ADD_EMI_DATA = "ADD_EMI_DATA";
export const ADD_EMI_DATA_SUCCESS = "ADD_EMI_DATA_SUCCESS";
export const DELETE_EMI_BY_ID = "DELETE_EMI_BY_ID";
export const DELETE_EMI_BY_ID_SUCCESS = "DELETE_EMI_BY_ID_SUCCESS";
export const UPDATE_EMI_BY_ID = "UPDATE_EMI_BY_ID";
export const UPDATE_EMI_BY_ID_SUCCESS = "UPDATE_EMI_BY_ID_SUCCESS";

// =========================================== user Actions ==========================================================
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const ADD_USER_DATA_SUCCESS = "ADD_USER_DATA_SUCCESS";
export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const DELETE_USER_BY_ID_SUCCESS = "DELETE_USER_BY_ID_SUCCESS";
export const UPDATE_USER_BY_ID = "UPDATE_USER_BY_ID";
export const UPDATE_USER_BY_ID_SUCCESS = "UPDATE_USER_BY_ID_SUCCESS";


// =========================================== interest ==========================================================
export const GET_ALL_INTERESTS = "GET_ALL_INTERESTS";
export const GET_ALL_INTERESTS_SUCCESS = "GET_ALL_INTERESTS_SUCCESS";
export const ADD_INTERESTS_DATA = "ADD_INTERESTS_DATA";
export const EDIT_INTERESTS_DATA = "EDIT_INTERESTS_DATA";
export const ADD_INTERESTS_DATA_SUCCESS = "ADD_INTERESTS_DATA_SUCCESS";
export const DELETE_INTERESTS_BY_ID = "DELETE_INTERESTS_BY_ID";
export const DELETE_INTERESTS_BY_ID_SUCCESS = "DELETE_INTERESTS_BY_ID_SUCCESS";
export const UPDATE_INTERESTS_BY_ID = "UPDATE_INTERESTS_BY_ID";
export const UPDATE_INTERESTS_BY_ID_SUCCESS = "UPDATE_INTERESTS_BY_ID_SUCCESS";

// =============================== EMI SECTION ========================================================
export const CREATE_EMI = "CREATE_EMI";
export const CREATE_EMI_SUCCESS = "CREATE_EMI_SUCCESS";
export const SHOW_LOAN_SUMMARY = "SHOW_LOAN_SUMMARY";
export const SHOW_LOAN_SUMMARY_SUCCESS = "SHOW_LOAN_SUMMARY_SUCCESS";
export const UPLOAD_SANCTION_LETTER = "UPLOAD_SANCTION_LETTER";
export const UPLOAD_SANCTION_LETTER_SUCCESS = "UPLOAD_SANCTION_LETTER_SUCCESS";
export const REFRESH_PAGE = "REFRESH_PAGE"