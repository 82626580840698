import Staff from "src/pages/staffSection";
import Users from "../pages/company";
import Dashboard from "../pages/Dashboard";
import PackageList from "../pages/PackageDetails/PackageList"
import Customers from "src/pages/customers";
import PreviewData from "src/pages/previewData";
import Schemes from "src/pages/packages";
import PackagesList from "src/pages/PackageDetails/PackageList/packagesList";

const AppRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/users", name: "Users", element: Users },
  { path: "/companies", name: "Hospitals", element: Users },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/packageList", name: "Schemes", element: PackageList },
  { path: "/staff", name: 'Staff', element: Staff },
  { path: "/customer", name: 'Customer', element: Customers },
  { path: "/preview-data", name: 'Customer', element: PreviewData },
  { path: "/schemes", name: "Hospitals", element: Schemes },
  { path: "/packageslist", name: "Packages List", element: PackagesList },
];

export default AppRoutes;
