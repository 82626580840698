import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  GET_ALL_EMI,
  GET_ALL_EMI_SUCCESS,
  ERROR,
  ADD_EMI_DATA,
  ADD_EMI_DATA_SUCCESS,
  DELETE_EMI_BY_ID,
  DELETE_EMI_BY_ID_SUCCESS,
  UPDATE_EMI_BY_ID,
  UPDATE_EMI_BY_ID_SUCCESS,
  CREATE_EMI_SUCCESS,
  CREATE_EMI,
  SHOW_LOAN_SUMMARY,
  SHOW_LOAN_SUMMARY_SUCCESS,
} from "../action_Types";

function* allEmi() {
  try {
    const response = yield call(authInstance.get, "customer-loans");

    yield put({
      type: GET_ALL_EMI_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addEmi(action) {
  try {
    const response = yield call(authInstance.post, "", action.payload);

    yield put({
      type: ADD_EMI_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteEmi(action) {
  try {
    const response = yield call(authInstance.delete, `/${action.payload}`);

    yield put({
      type: DELETE_EMI_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateEmi(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `${id}`, data);

    yield put({
      type: UPDATE_EMI_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* createEmi(action) {
  try {
    const response = yield call(authInstance.post, "emi", action.payload);

    yield put({
      type: CREATE_EMI_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* showSummaryDetails(action) {
  
  try {
    const response = yield call(authInstance.post, "getAllEmi", action.payload);
    yield put({
      type: SHOW_LOAN_SUMMARY_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* emiActionWatcher() {
  yield takeLatest(GET_ALL_EMI, allEmi);
  yield takeLatest(ADD_EMI_DATA, addEmi);
  yield takeLatest(CREATE_EMI, createEmi);
  yield takeLatest(SHOW_LOAN_SUMMARY, showSummaryDetails);
  yield takeLatest(DELETE_EMI_BY_ID, deleteEmi);
  yield takeLatest(UPDATE_EMI_BY_ID, updateEmi);
}
