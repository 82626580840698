import React, { useEffect, useState } from "react";
import { CButton, CCard, CCardBody, CCol, CFormInput, CFormSelect, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import { getAllScehems, deleteScheme } from "src/api/allApis";
import _ from "lodash";
import AddPackage from "./addPackage";
import { CSVLink } from "react-csv";

const Schemes = () => {
  const { data } = useSelector((state) => state?.companyReducer);
  const [model, setModel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editLoanData, setEditLoanData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [updateForm, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyModel, setCompanyModel] = useState(false);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [visibleUser, setVisibleUser] = useState(false);
  const handleShow = () => setShowModal(true);
  const [schemes, setSchemes] = useState([]);
  const [_refresh, setRefresh] = useState(1);

  const fetchSchemes = async () => {
    const res = await getAllScehems({ role: "super admin" });
    setSchemes(res?.data || []);
  };

  useEffect(() => {
    fetchSchemes();
  }, [_refresh]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = schemes?.slice(indexOfFirstRow, indexOfLastRow);

  const handleEditLoan = (loanData) => {
    setUpdate(true);
    setEditLoanData(loanData);
    setModel("update");
  };

  const handleViewDetails = async (company) => {
    await deleteScheme({ id: company?.id, role: "super admin" });
    refresh();
  };

  const handleshowAddForm = () => {
    setVisibleUser(true);
    setModel("add");
  };

  const refresh = () => {
    setRefresh(_refresh + 1);
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">         

          <div className="search_bar">
            {showSelectAndSearch && (
              <div>
                <CFormSelect size="sm" className="mb-3" style={{ cursor: "pointer" }} aria-label="Small select example" onChange={(e) => setRowsPerPage(e.target.value)}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </CFormSelect>
              </div>
            )}
            <div style={{ display: "flex" }}>
              {showSelectAndSearch && (
                <>
                  <CFormInput type="text" size="sm" placeholder="Search" aria-label="sm input example" style={{ margin: "0px 0px 16px 0px" }} onChange={handleSearchChange} value={searchInput} />

                  <span
                    style={{
                      border: " 1px solid #d1c5c5",
                      borderRadius: "4px",
                      margin: "0px 5px 16px -1px",
                      padding: "1px 8px 0px 8px",
                      background: "#ebedef",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </span>
                </>
              )}
            </div>
            {showSelectAndSearch && (
            <div className="btn_add mt-0 mb-14">
              <CButton className="add-btn w-auto plr-30 mr-10" onClick={handleshowAddForm}>
                Add
              </CButton>
              <CSVLink data={currentRows}>
                <CButton className="add-btn w-auto plr-30 mr-10" style={{marginLeft: 20, marginRight: 90}}>
                  Download
                </CButton>
              </CSVLink>
              ;
            </div>
          )}
          </div>

          <CCardBody >
            <div className={`table-container ${showAccordion ? "hidden" : ""}`}>
              <CTable color="dark" hover className="mb-2">
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="col-md-1">
                      S. No.
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="col-md-2">
                      Plan
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="col-md-2">
                      Limit
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="col-md-2">
                      Duration (Months)
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="col-md-2">
                      Amount
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="col-md-2">
                      Active
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="col-md-3">
                      Action
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {schemes && schemes.length > 0 ? (
                    currentRows?.map((company, index) => (
                      <CTableRow key={index}>
                        <CTableDataCell >{indexOfFirstRow + index + 1}</CTableDataCell>
                        <CTableDataCell>{company?.schemename || "--"}</CTableDataCell>
                        <CTableDataCell>{company?.limit || "--"}</CTableDataCell>
                        <CTableDataCell>{company?.duration_months || "--"}</CTableDataCell>
                        <CTableDataCell>{company?.amount || "--"}</CTableDataCell>
                        <CTableDataCell>{_.isEqual(company?.active, true) ? "Active" : "InActive"}</CTableDataCell>
                        <CTableDataCell className="col-md-3">
                          <>
                            <Tooltip text="Update">
                              <FontAwesomeIcon role="button" icon={faPenToSquare} size="1x" onClick={() => handleEditLoan(company)} className="px-3 updateIcon" />
                            </Tooltip>
                            <Tooltip text="View">
                              <FontAwesomeIcon role="button" icon={faTrash} size="1x" className="viewIcon" onClick={() => handleViewDetails(company)} />
                            </Tooltip>
                          </>
                        </CTableDataCell>
                      </CTableRow>
                    ))
                  ) : (
                    <div className="loading_data">Loading...</div>
                  )}
                </CTableBody>
              </CTable>
              {model === "update" ? <AddPackage setVisible={setModel} visible={model} refresh={refresh} isUpdate={true} company={editLoanData} /> : ""}
              {model === "add" ? <AddPackage setVisible={setModel} visible={model} refresh={refresh} /> : ""}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Showing {indexOfFirstRow + 1} to {Math?.min(indexOfLastRow, data?.data?.length)} of {data?.data?.length} entries
                </div>

                <div className="pagination-container">
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={data?.data?.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText="Previous"
                    nextPageText="Next"
                    firstPageText="First"
                    lastPageText="Last"
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs={12}>
        <div className={`accordion-container ${showAccordion ? "visible" : ""}`}></div>
      </CCol>
    </CRow>
  );
};

export default Schemes;
