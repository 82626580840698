import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  ERROR,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER,
  ADD_USER_DATA,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
  ADD_USER_DATA_SUCCESS,
  DELETE_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_SUCCESS,
  UPDATE_CUSTOMER_STATUS_SUCCESS,
  UPDATE_CUSTOMER_STATUS,
  UPLOAD_SANCTION_LETTER_SUCCESS,
  UPLOAD_SANCTION_LETTER,
} from "../action_Types";

function* allUser() {
  try {
    const response = yield call(authInstance.get, "");

    yield put({
      type: GET_ALL_USER_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}
function* CompanyStatus(action) {
  const { id, data } = action.payload;

  try {
    const response = yield call(authInstance.patch, `customers/${id}`, data);

    yield put({
      type: UPDATE_CUSTOMER_STATUS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}
function* uploadLetter(action) {
  try {
    const response = yield call(
      authInstance.post,
      "sanction-letter",
      action.payload
    );

    yield put({
      type: UPLOAD_SANCTION_LETTER_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addUser(action) {

  try {
    const response = yield call(authInstance.post, "customers", action.payload);
    

    yield put({
      type: ADD_USER_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
  
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteUser(action) {
  try {
    const response = yield call(authInstance.delete, `/${action.payload}`);

    yield put({
      type: DELETE_USER_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateUser(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `/${id}`, data);

    yield put({
      type: UPDATE_USER_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* customerActionWatcher() {
  yield takeLatest(GET_ALL_USER, allUser);
  yield takeLatest(ADD_USER_DATA, addUser);
  yield takeLatest(DELETE_USER_BY_ID, deleteUser);
  yield takeLatest(UPDATE_USER_BY_ID, updateUser);
  yield takeLatest(UPDATE_CUSTOMER_STATUS, CompanyStatus);
  yield takeLatest(UPLOAD_SANCTION_LETTER, uploadLetter);
}
