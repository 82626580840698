import { CButton, CCard, CCardBody, CCol, CRow } from "@coreui/react";
import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useReactToPrint } from "react-to-print";
import PrintComp from "./printComp";

const ComponentToPrint = ({ contentRef, data }) => {
  return <PrintComp mainRef={contentRef} data={data} />;
};

const NomineeGroup = (props = {}) => {
  const contentRef = React.useRef();
  const location = useLocation();
  const { data } = location.state || {};
  const handleDownload = useReactToPrint({
    content: () => contentRef.current,
  });

  return (
    <div>
      <div style={{ display: "none" }}>
        <ComponentToPrint contentRef={contentRef} data={data} />
      </div>

      <CRow className="print_customer">
        <CCol>
          <CCard>
            <CCardBody>
              <CCard>
                <CCardBody className="background-container">
                  <CRow className="justify-content-center right">
                    <CCol style={{ width: "100%" }} xs={8}>
                      <div className="mt-2"></div>
                      <CRow>
                        <CCol xs={6}>
                          <label className="label">Customer Name  : {data?.consumerName}</label>
                        </CCol>
                        <CCol xs={6}>
                          <label>Mobile Number : {data?.mobileNumber || "-"} </label>
                        </CCol>
                      </CRow>

                      <div className="mt-2"></div>
                      <CRow>
                        <CCol xs={6}>
                          <label className="label">Email Id : {data?.email}</label>
                        </CCol>
                        <CCol xs={6}>
                          <label className="label">Address : {data?.address}</label>
                        </CCol>
                      </CRow>

                      <div className="mt-2"></div>
                      <CRow>
                        <CCol xs={6}>
                          <label>Scheme Name: {data?.packageDetails?.packageName} </label>
                        </CCol>
                        <CCol xs={6}>
                          <label>Scheme Start Date : {data?.packageDetails?.packageStartingData} </label>
                        </CCol>
                      </CRow>
                      <div className="mt-2"></div>
                      <CRow>
                        <CCol xs={6}>
                          <label className="label">Scheme End Date : {data?.packageDetails?.packageEndData}</label>
                        </CCol>
                      </CRow>
                      <div className="mt-2"></div>
                      <div className="mt-2"></div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
              <div className="identification_btn download-button">
                <CButton style={{ margin: "0px -9px 18px 100px" }} className="next_btn" onClick={handleDownload}>
                  Download
                </CButton>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};
NomineeGroup.propTypes = {
  formdata: PropTypes.object.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  contentRef: PropTypes.func,
};
ComponentToPrint.propTypes = {
  formdata: PropTypes.object.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  contentRef: PropTypes.func,
  data: PropTypes.func,
};
export default NomineeGroup;
