import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";

import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CListGroup,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getallPackage } from "src/api/packageApi";

const initialValues = {
  packageName: "",
  packageStartingData: "",
  packageAmount: "",
  packageEndData: "",
};

const Adress = (props) => {
  const _data = props.prefill || {};
  const _prefill = {
    packageName: _data?.packageDetails?.packageName,
    packageAmount: _data?.packageDetails?.packageAmount,
    packageStartingData: _data?.packageDetails?.packageStartingData,
    packageEndData: _data?.packageDetails?.packageEndData,
  }

  const [submission, setSubmission] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [packageInfo, setPackageInfo] = useState([]);
  const [packages, setpackages] = useState(_prefill || props?.formData || initialValues);
  const SchemaName = sessionStorage.getItem("schemaName");

  useEffect(() => {
    getAllPackageData();
  }, []);
  // const validationSchema = Yup.object().shape({
  //   state: Yup.string().required("state is required"),
  //   district: Yup.string().required("district is required"),
  //   city: Yup.string().required("city  is required"),
  //   area: Yup.string().required("area is required"),
  //   country: Yup.string().required("country is required"),
  //   pincode: Yup.number().required("pincode is required"),
  //   socialSecurityNumber: Yup.string().required("SSN or NIN no is required"),
  // });

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setpackages({ ...packages, [name]: value });
  };

  const handleSubmit = (values) => {
    props.updateFormData("customerPackages", packages);
    props.setKey("Nominee", values);
    setSubmission(true);
  };

  const getAllPackageData = () => {
    const payload = { schemaName: SchemaName };
    getallPackage(payload)
      .then((res) => setPackageData(res?.data?.result)
      )
      .catch((err) => console.log(err));
  };

  const handlePackageSelect = (e) => {
    const selectedPackageName = e.target.value;
    const selectedPackage = packageData?.find(
      (pkg) => pkg.packageName === selectedPackageName
    );

    if (selectedPackage) {
      const { packageName, amount, startDate, dueDate } = selectedPackage;
      setpackages({
        ...packages,
        packageName: packageName,
        packageAmount: amount,
        packageStartingData: startDate,
        packageEndData: dueDate,
      });
    }
  };

  const { packageName, packageAmount, packageStartingData, packageEndData } =
    packages;



  return (
    <CCardBody>
      <CContainer>
        <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="packageName">Scheme Name</CFormLabel>
              <CFormSelect
                size="md"
                className="mb-3"
                aria-label="select Role"
                id="packageName"
                defaultValue="true"
                name="packageName"
                value={packageName}
                // onChange={onChangeValue}
                onChange={handlePackageSelect}
                required
              >
                <option value="">Select Option</option>
                {packageData?.map((item, index) => {
                  return (
                    <option key={index} value={item.packageName}>
                      {item.packageName}
                    </option>
                  );
                })}
              </CFormSelect>
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="packageAmount">Scheme Amount</CFormLabel>
              <CFormInput
                type="text"
                id="packageAmount"
                placeholder="Package Amount"
                name="packageAmount"
                value={packageAmount}
                onChange={onChangeValue}
                required
                disabled
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="packageStartingData">
                Scheme Starting Data
              </CFormLabel>
              <CFormInput
                type="text"
                id="packageStartingData"
                placeholder="Package Starting Data"
                name="packageStartingData"
                value={packageStartingData}
                onChange={onChangeValue}
                required
                disabled
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="packageEndData">Scheme End Data</CFormLabel>
              <CFormInput
                type="text"
                id="packageEndData"
                placeholder="Package Starting Data"
                name="packageEndData"
                value={packageEndData}
                onChange={onChangeValue}
                required
                disabled
              />
            </CCol>
          </CRow>

          <div className="identification_btn">
            <CButton className="next_btn" type="submit">
              Next{" "}
            </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};
Adress.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
  prefill: PropTypes.object
};
export default Adress;