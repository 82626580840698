import { useEffect, useState } from "react";
import { addStaff, updateStaff, deleteStaff } from "src/api/staffApi";

const initialState = {
  userName: "",
  password: "",
  hospitalName: "test",
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  activeStatus: "active",
};

export const Porvider = ({ setShow, operation, sendResData }) => {

  const [staffData, setStaffData] = useState({ initialState });
  const [errors, setErrors] = useState({});
  const SchemaName = sessionStorage.getItem("schemaName")

  useEffect(() => {
    newStaffData();
  }, []);

  console.log("operation", operation)

  const newStaffData = () => {
    setStaffData(sendResData);
  };

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setStaffData({ ...staffData, [name]: value });

    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const validateForm = () => {
    const { userName, firstName, lastName, password, mobile, email } =
      staffData;
    const newErrors = {};

    if (operation != "addstaff" && !userName || userName === "") {
      newErrors.userName = "Please enter user name";
    }

    if (operation != "addstaff" && !password || password === "") {
      newErrors.password =
        "Please enter a valid password & at least 8 characters";
    }


    if (!firstName || firstName === "") {
      newErrors.firstName = "Please enter first name";
    }

    if (!lastName || lastName === "") {
      newErrors.lastName = "Please enter last name";
    }

    if (!mobile) {
      newErrors.mobile = "Please enter mobile number";
    }

    if (!/^[0-9]{10}$/.test(mobile)) {
      newErrors.mobile = "Please enter 10 digits mobile number";
    }

    if (!email) {
      newErrors.email = "Please enter a valid email address";
    }

    return newErrors;
  };



  const handleSubmit = async () => {

    if (operation == 'addstaff') {
      const formErrors = validateForm();

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        const payload = {
          schemaName: SchemaName,
          userName,
          password,
          hospitalName: "test",
          firstName,
          lastName,
          email,
          mobile,
          activeStatus
        };
        addStaff(payload)
          .then((res) => {
            if (res.status == "success") {
              setShow(false);
            }
          })
          .catch((err) => alert(err));
      }
    }
    else if (operation == 'updatestaff') {
      const payload = {
        schemaName: SchemaName,
        // userName,
        // password,
        hospitalName: "test",
        firstName,
        lastName,
        email,
        mobile,
      };
      updateStaff(payload, sendResData.id)
        .then((res) => {
          if (res.status == "success") {
            setShow(false);
          }
        })
        .catch((err) => alert(err));
    }

    else if (operation == 'deletestaff') {
      alert("delete staff entered")
      const body = {
        schemaName: SchemaName,
      }
      deleteStaff(body, sendResData.id)
        .then((res) => {
          if (res.status == "success") {
            setShow(false);
          }
        })
        .catch((err) => alert(err));
    }
  };

  //destructure
  const { userName, password, firstName, lastName, email, mobile, activeStatus } = staffData;

  return {
    staffData,
    onChangeValue,
    handleSubmit,
    errors,
  };
};
