import { useEffect, useState } from "react";
import { getAllScehems } from "src/api/allApis";
import { getAllCompanies } from "src/api/hospitalApi";

export const Porvider = () => {

    const [usersCount, setUsersCount] = useState(0);
    const [incomeCount, setIncomeCount] = useState(0);

    useEffect(() => {
        getAllCompany();
        getAllPackages();
    }, [])

    const getAllCompany = () => {
        getAllCompanies()
            .then(res => setUsersCount(res?.data?.length))
    }

    const getAllPackages = () => {
        getAllScehems()
            .then(res => console.log(res))
    }

    return {
        usersCount
    }
}