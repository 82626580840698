import { ADD_COMPANY_DATA, DELETE_COMPANY_BY_ID, GET_ALL_COMPANY, UPDATE_COMPANY_BY_ID, DELETE_LOAN_BY_ID } from "../action_Types";

export const getAllHospitals = () => {
  return {
    type: GET_ALL_COMPANY,
  };
};

export const deleteCompany = (id) => {
  return {
    type: DELETE_COMPANY_BY_ID,
    payload: id,
  };
};

export const deleteLoan = (id) => {
  return {
    type: DELETE_LOAN_BY_ID,
    payload: id,
  };
};

export const addCompany = (data) => {

  return {
    type: ADD_COMPANY_DATA,
    payload: data,
  };
};

export const updateCompany = (id) => {

  return {
    type: UPDATE_COMPANY_BY_ID,
    payload: id,
  };
};