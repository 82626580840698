import {
  ADD_STAFFS_DATA_SUCCESS,
  EDIT_STAFFS_DATA,
  ADMINISTRAOR_LOGIN_SUCCESS,
  ADMIN_LOGIN_SUCCESS,
  ERROR,
  GET_ALL_STAFFS_SUCCESS,
  SELECTEDROLE,
  SET_ROUTE_VALUE,
  SET_SCHEMANAME,
  STAFF_LOGIN_SUCCESS,
} from "../action_Types";
const initialState = {
  data: null,
  message: "",
  error: false,
  sidebarShow: true,
  routeValue: "",
  selectedRoleType: "",
  SCHEMANAME: "",
  update: false
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STAFFS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
        sidebarShow: true,
        staffData: null,
        adminstratorData: null,
        adminData: null
      };
    case "set":
      return {
        ...state,
        sidebarShow: action.sidebarShow,
      };

    case ADD_STAFFS_DATA_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
      };
    case EDIT_STAFFS_DATA:
      return {
        ...state,
        update: true,
        error: false,
      };
    case SET_SCHEMANAME:
      return {
        ...state,
        update: true,
        SCHEMANAME: action.payload,
        error: false,
      };
    case SET_ROUTE_VALUE:
      return {
        ...state,
        update: true,
        error: false,
        routeValue: action.payload,
      };
    case STAFF_LOGIN_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
        staffData: action.payload,
      };
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        update: true,
        error: false,
        adminData: action.payload,
      };
    case ADMINISTRAOR_LOGIN_SUCCESS:
      sessionStorage.setItem('emplyeeId', action?.payload?.data?.employeeId)
      return {
        ...state,
        update: true,
        error: false,
        data: action.payload,
      };
    case SELECTEDROLE:
      return {
        ...state,
        update: true,
        error: false,
        selectedRoleType: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default staffReducer;
