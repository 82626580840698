import React, { useState, useEffect } from "react";
import { CButton, CCol, CContainer, CForm, CFormInput, CFormSelect, CRow, CFormLabel, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CCardBody } from "@coreui/react";
import { addPackageList, getallPackage } from "src/api/packageApi";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import Tooltip from "src/components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PackagesList() {
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    type: "percentage",
    amount: "",
  });
  const id = location?.state?.data;
  const SchemaName = sessionStorage.getItem("schemaName");
  const [_packages, setPackages] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let packages = [..._packages, { id: Math.random(), name: formData?.name, type: formData?.type, amount: formData?.amount }];
      if (formData?.id) {
        packages = _packages;
        packages = packages?.map((item) => {
          if (item?.id === formData?.id) return { id: formData?.id, name: formData?.name, type: formData?.type, amount: formData?.amount };
          else return item;
        });
      }
      const payload = { schemaName: SchemaName, id, packages };
      await addPackageList(payload);
      setFormData({
        name: "",
        type: "percentage",
        amount: "",
      });
      setRefresh(refresh + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPackages = async () => {
    const res = await getallPackage({ schemaName: SchemaName });
    const _pack = res?.data?.result?.find((item) => item.id === id);
    setPackages(_pack?.packages || []);
  };

  const handleEdit = (item) => () => {
    setFormData(item);
  };

  const handleDelete = (value) => async () => {
    const packages = _packages?.filter((item) => item?.id !== value?.id);
    const payload = { schemaName: SchemaName, id, packages };
    await addPackageList(payload);
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    fetchPackages();
  }, [refresh]);

  return (
    <CContainer>
      <CForm onSubmit={handleSubmit}>
        <CRow>
          <CCol>
            <CFormLabel htmlFor="name">Name</CFormLabel>
            <CFormInput value={formData?.name} id="name" name="name" type="text" placeholder="Enter Name" onChange={handleInputChange} />
          </CCol>
          <CCol>
            <CFormLabel htmlFor="type">Type of Amount</CFormLabel>
            <CFormSelect value={formData?.type} id="type" name="type" onChange={handleInputChange}>
              <option value="percentage">Percentage</option>
              <option value="absolute">Absolute</option>
            </CFormSelect>
          </CCol>
          <CCol>
            <CFormLabel htmlFor="amount">Amount</CFormLabel>
            <CFormInput value={formData?.amount} type="number" id="amount" name="amount" placeholder="Enter Amount" onChange={handleInputChange} />
          </CCol>
          <CCol xs={5}>
            <CButton type="submit" color="primary" className="px-4" style={{ marginTop: "2rem" }}>
              Submit
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <CFormLabel style={{ marginTop: 30 }} htmlFor="type">
        List of Packages
      </CFormLabel>
      <CContainer>
        <CCardBody>
          <CTable color="dark" hover className="">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col" className="col-md-1">
                  SR. No.
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="col-md-3">
                  Name
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="col-md-3">
                  Type of Amount
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="col-md-3">
                  Amount
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="col-md-2">
                  Action
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {_packages?.map((item, index) => {
                return (
                  <CTableRow key={index}>
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>{item?.name}</CTableDataCell>
                    <CTableDataCell>{item?.type}</CTableDataCell>
                    <CTableDataCell>{item?.amount}</CTableDataCell>
                    <CTableDataCell className="col-md-3">
                      <>
                        <Tooltip text="Update">
                          <FontAwesomeIcon role="button" icon={faPenToSquare} size="1x" onClick={handleEdit(item)} className="pr-3 updateIcon" />
                        </Tooltip>
                        <Tooltip text="Update">
                          <FontAwesomeIcon role="button" icon={faTrash} size="1x" onClick={handleDelete(item)} className="px-3 updateIcon" />
                        </Tooltip>
                      </>
                    </CTableDataCell>
                  </CTableRow>
                );
              })}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CContainer>
    </CContainer>
  );
}
