import React, { useRef, useState } from "react";
import { CButton, CCardBody, CCol, CContainer, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react";
import * as Yup from "yup";
import PropTypes from 'prop-types';

const initialValues = {
  invoiceNumber: "",
  billingAmount: "",
  paymentDate: "",
};

const IdentiFicationForm = (props) => {

  const [identification, setIdentification] = useState(props?.formData || initialValues)
  const SchemaName = sessionStorage.getItem("schemaName")

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setIdentification({ ...identification, [name]: value });
  };


  // const validationSchema = Yup.object().shape({
  //   aadharCard: Yup.string()
  //     .typeError("please select a file")
  //     .required("invoiceNumber is required"),
  //   invoiceNumber: Yup.number().required("AadharCard is required"),
  //   billingAmount: Yup.number().required("PanCard is required"),
  //   permanentResidencyStatus: Yup.string().required(
  //     "Permanent Residency Status is required"
  //   ),
  //   panCard: Yup.string()
  //     .typeError("please select a file")
  //     .required("panCard is required"),
  //   panImageUrl: Yup.mixed()
  //     .required('Image is required')
  //     .test(
  //       'fileSize',
  //       'File size is too large',
  //       (value) => value && value.size <= 1024000 // Adjust the size limit as needed (in bytes)
  //     )
  //     .test(
  //       'fileType',
  //       'Unsupported file format',
  //       (value) => value && ['image/jpeg', 'image/png'].includes(value.type)
  //     ),
  // });

  const handleSubmit = (values) => {

    const obj = {
      invoiceNumber: SchemaName + invoiceNumber,
      billingAmount: billingAmount,
      paymentDate: paymentDate,
    }
    props.updateFormData("identiFication", obj);
    props.setKey("Nominee", values);
  };

  const {
    invoiceNumber,
    billingAmount,
    paymentDate,
  } = identification

  return (
    <CCardBody>
      <CContainer>
        <CForm
          onSubmit={(event) => handleSubmit(event)}
          autoComplete="off"
        >
          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="invoiceNumber">
                Invoice No.
              </CFormLabel>
              <CFormInput
                id="invoiceNumber"
                type="text"
                placeholder={SchemaName}
                name="invoiceNumber"
                value={invoiceNumber}
                onChange={onChangeValue}
                required
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="billingAmount">Billing Amount</CFormLabel>
              <CFormInput
                id="billingAmount"
                type="number"
                placeholder="Billing Amount"
                name="billingAmount"
                value={billingAmount}
                onChange={onChangeValue}
                required
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="paymentDate">Payment Date</CFormLabel>
              <CFormInput
                id="paymentDate"
                type="date"
                placeholder="Payment Date"
                name="paymentDate"
                value={paymentDate}
                onChange={onChangeValue}
                required
              />
            </CCol>
          </CRow>

          <div className="identification_btn">
            <CButton type="submit" className='next_btn'>Next </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};
IdentiFicationForm.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
};
export default IdentiFicationForm;
