import React, { useEffect, useState } from "react";
import { getAllStaff } from "src/api/staffApi";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CFormInput,
  CFormSelect,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import CompanyAddAndUpdateForm from "src/components/Modal/editAndAddForm";
import ReactPaginate from "react-js-pagination";
import CompanyAllDetail from "../company/companyAllDetails";
import AddDataModel from "./AddStaffForm";
import { CSVLink } from "react-csv";

const Staff = () => {
  const [resData, setResData] = useState([]);
  const [sendResData, setSendResData] = useState([]);
  const [show, setShow] = useState(false);
  const [operation, setOpearation] = useState("");
  const [showAccordion, setShowAccordion] = useState(false);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [model, setModel] = useState(null);
  const [editLoanData, setEditLoanData] = useState(null);
  const [updateForm, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = resData?.slice(indexOfFirstRow, indexOfLastRow);
  const SchemaName = sessionStorage.getItem("schemaName")

  console.log("rows",currentRows);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getAllStaffData();
  }, [setShow]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleDeleteClick = (item) => {
    setShow(true);
    setOpearation("deletestaff");
    setSendResData(item); //data with id
  };

  const handleEditStaff = (item) => {
    setSendResData(item);
    setShow(true);
    setOpearation("updatestaff");
  };


  const handleViewDetails = (item) => {
    setShow(true);
    setOpearation("viewstaff");
  };

  const handleshowAddForm = () => {
    setShow(true);
    setOpearation("addstaff")
  };


  const getAllStaffData = () => {
    const payload = { schemaName: SchemaName }
    getAllStaff(payload)
      .then(result => setResData(result.data))
      .catch(error => console.log(error))
  }

  return (
    <>
      {show && <AddDataModel show={show} setShow={setShow} operation={operation} sendResData={sendResData} />}
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Staff List</strong>
            </CCardHeader>

            {/* added.......... */}
            <div className="search_bar">
              {showSelectAndSearch && (
                <div>
                  <CFormSelect
                    size="sm"
                    className="mb-3"
                    style={{ cursor: "pointer" }}
                    aria-label="Small select example"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </CFormSelect>
                </div>
              )}
              <div style={{ display: "flex" }}>
                {showSelectAndSearch && (
                  <>
                    <CFormInput
                      type="text"
                      size="sm"
                      placeholder="Search"
                      aria-label="sm input example"
                      style={{ margin: "0px 0px 16px 0px" }}
                      onChange={handleSearchChange}
                      value={searchInput}
                    />

                    <span
                      style={{
                        border: " 1px solid #d1c5c5",
                        borderRadius: "4px",
                        margin: "0px 5px 16px -1px",
                        padding: "1px 8px 0px 8px",
                        background: "#ebedef",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </>
                )}
              </div>
              {showSelectAndSearch && (
                <div className="btn_add mt-0 mb-14">
                  <CButton className="add-btn w-auto plr-30 mr-10" onClick={handleshowAddForm}>
                    Add
                  </CButton>
                  <CSVLink data={currentRows} filename={'Staff_Data.csv'}>
                    <CButton className="add-btn w-auto plr-30 mr-10" style={{ marginLeft: 20, marginRight: 90 }}>
                      Download
                    </CButton>
                  </CSVLink>

                </div>
              )}



            </div>

            <CCardBody>
              <div
                className={`table-container ${showAccordion ? "hidden" : ""}`}
              >
                <CTable color="dark" hover className="">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="col-md-1">
                        S. No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        First Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Last Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Mobile No
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-3">
                        Mail Id
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-3">
                        Action
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {
                      resData && resData?.length > 0 ? (
                        currentRows?.filter((res) =>
                          res?.firstName?.toLowerCase()
                            .includes(searchInput.toLowerCase())
                        )
                          .map((item, index) => {
                            return (
                              <CTableRow key={index}>
                                <CTableDataCell >{index + 1}</CTableDataCell>
                                <CTableDataCell>
                                  {item?.firstName || "--"}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {item?.lastName || "--"}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {item?.mobile || "--"}
                                </CTableDataCell>
                                <CTableDataCell>
                                  {item?.email || "--"}
                                </CTableDataCell>
                                <CTableDataCell className="col-md-6">
                                  <>
                                    <Tooltip text="Update">
                                      <FontAwesomeIcon
                                        role="button"
                                        icon={faPenToSquare}
                                        size="1x"
                                        onClick={() => handleEditStaff(item)}
                                        className="px-3 updateIcon"
                                      />
                                    </Tooltip>
                                  </>
                                </CTableDataCell>
                              </CTableRow>
                            )
                          })) : (
                        <CTableDataCell
                          colSpan={6}
                          style={{ textAlign: "center", color: "red !important" }}
                        >
                          No Record Found!
                        </CTableDataCell>
                      )}
                  </CTableBody >
                </CTable>

                {/* start */}
                {model === "update" ? (
                  <CompanyAddAndUpdateForm
                    setVisible={setModel}
                    visible={model}
                    loanData={editLoanData}
                    updateForm={updateForm}
                  />
                ) : (
                  ""
                )}

                {model === "add" ? (
                  <CompanyAddAndUpdateForm
                    setVisible={setModel}
                    visible={model}
                  />
                ) : (
                  ""
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >

                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    Showing {indexOfFirstRow + 1} to{" "}
                    {Math?.min(indexOfLastRow, resData?.length)} of{" "}
                    {resData?.length} entries
                  </div>

                  <div className="pagination-container">
                    <ReactPaginate
                      activePage={currentPage}
                      itemsCountPerPage={rowsPerPage}
                      totalItemsCount={resData?.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText="Previous"
                      nextPageText="Next"
                      firstPageText="First"
                      lastPageText="Last"
                      innerClass="pagination"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>

                {/* end */}
              </div>
              {/* .. */}
              {showAccordion && (
                <CompanyAllDetail
                  selectedCompany={selectedCompany}
                  setShowAccordion={setShowAccordion}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                />
              )}
              {/* ... */}
            </CCardBody>
          </CCard >
        </CCol >

        <CCol xs={12}>
          <div
            className={`accordion-container ${showAccordion ? "visible" : ""}`}
          ></div>
        </CCol>
      </CRow >
    </>
  );
};


export default Staff;
